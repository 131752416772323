import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import eventimg from "../assets/fees-payment.jpeg";

const SchoolFees = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      {/* Header Section */}
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F]">School Fees</h2>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container-fluid">
        <div className="container my-5 px-5 lg:px-0">
          <div className="flex flex-col lg:flex-row gap-10 items-center">
            {/* Image Section */}
            <div
              className="w-full lg:w-1/2 flex justify-center"
              data-aos="fade-right"
            >
              <img
                src={eventimg}
                alt="School Fees"
                className="w-full lg:w-2/3 h-auto transition-transform duration-300 hover:scale-105 rounded-md"
              />
            </div>

            {/* Text Section */}
            <div
              className="w-full lg:w-1/2"
              data-aos="fade-left"
            >
              <h3 className="admission-heading text-2xl text-[#13366F] transition-colors duration-300 hover:text-blue-600 mb-4">
                School Fees
              </h3>
              <p className="fee-schedule-title text-lg font-semibold" data-aos="fade-up">
                Mode of Payment of School Fee
              </p>
              <p data-aos="fade-up" className="mb-4">
                School fee is payable in quarterly instalments on production of
                a fee slip issued by the school at the beginning of each
                quarter. The fee should be paid by the 7th day of the month. The
                payment of fee can be made through cheque or demand draft. Name
                of the student, Class, Section, Admission Number, and Mobile /
                Residence Telephone No. should be written on the back of the
                cheque. For Nursery, the admission fee details will be put up on
                the school notice board at the time the admissions are
                finalized.
              </p>
              <p className="fee-schedule-title text-lg font-semibold" data-aos="fade-up">
                Fee Schedule Payment Month
              </p>
              <ul className="fee-list list-disc pl-5 mt-3 space-y-2" data-aos="fade-up">
                <li className="transition-colors duration-300 hover:text-blue-600">
                  1st Quarter (Apr to Jun) - April
                </li>
                <li className="transition-colors duration-300 hover:text-blue-600">
                  2nd Quarter (Jul to Sep) - July
                </li>
                <li className="transition-colors duration-300 hover:text-blue-600">
                  3rd Quarter (Oct to Dec) - October
                </li>
                <li className="transition-colors duration-300 hover:text-blue-600">
                  4th Quarter (Jan to Mar) - January
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolFees;
