import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import eventimg from "../assets/beyond-academics-overview.JPG";

const BeyondAcademicOverview = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F]">Overview</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 flex justify-between px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex w-full gap-10" 
            data-aos="fade-up"
            style={{ display: "flex", gap: "10%", alignItems: "center" }}
          >
            {/* Image Section */}
            <div className="w-1/2 flex-shrink-0">
              <img
                src={eventimg}
                alt="about us"
                className="w-full h-auto mt-5 transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-1/2 flex flex-col">
              <h3 
                className="text-2xl text-[#13366F] transition-colors duration-300 hover:text-blue-600"
                data-aos="fade-up"
              >
                Confident Future
              </h3>

              <p data-aos="fade-up" className="my-4">
                For true integrated growth to take place, our children’s
                physical self must be systematically developed. Sports
                facilities at St. Joseph’s Mission School include indoor games
                and outdoor sports. The sports curriculum is designed according
                to the needs of the different age groups.
              </p>

              <p data-aos="fade-up" className="my-4">
                In the pre-primary and primary classes, emphasis is laid on the
                development of gross motor skills, physical stamina,
                flexibility, balance, strength, hygiene, and overcoming physical
                fears.
              </p>

              <p data-aos="fade-up" className="my-4">
                Indoor facilities are available for table tennis, chess, carom,
                and gymnasium. Outdoor sports include basketball, tennis,
                badminton, cricket, football, and athletics. Total personality
                development is one area where particular care is taken to
                provide for the intrinsic needs of the individual child, who is
                able to participate in some event/activity of his/her choice and
                gets the ideal kind of exposure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeyondAcademicOverview;
