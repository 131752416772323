import React, { useState, useEffect } from "react";
import axios from "axios";
import videoSource from "../videos/SHIP_4.mp4";
import blinkingIcon from "../assets/new-icon-gif-4.gif";

const Carousel = () => {
  const [notice, setNotice] = useState("");
  const [formData, setFormData] = useState({
    parent_name: "",
    email: "",
    mobile: "",
    city: "",
    query: "",
  });
  const [errors, setErrors] = useState({});
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const validateField = (name, value) => {
    let error = "";
    const nameRegex = /^[a-zA-Z\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const cityRegex = /^[a-zA-Z\s]*$/;

    switch (name) {
      case "parent_name":
        if (!value.trim()) error = "Name is required.";
        else if (!nameRegex.test(value)) error = "Name can only contain letters and spaces.";
        break;
      case "email":
        if (!value.trim()) error = "Email is required.";
        else if (!emailRegex.test(value)) error = "Enter a valid email address.";
        break;
      case "mobile":
        if (!value.trim()) error = "Mobile number is required.";
        else if (!phoneRegex.test(value)) error = "Mobile number must be 10 digits.";
        break;
      case "city":
        if (!value.trim()) error = "City is required.";
        else if (!cityRegex.test(value)) error = "City can only contain letters and spaces.";
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: validateField(name, value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) newErrors[field] = error;
    });
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contact-form`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      alert("Form submitted successfully!");
      setFormData({
        parent_name: "",
        email: "",
        mobile: "",
        city: "",
        query: "",
      });
      setErrors({});
    } catch (error) {
      alert("There was an error submitting the form. Please try again.");
    }
  };
  

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-notice`);
        const notices = response.data;
        if (Array.isArray(notices) && notices.length > 0) {
          const noticeTexts = notices.map((item) => item.notice).join(" | ");
          setNotice(noticeTexts);
        } else {
          setNotice("No notices available");
        }
      } catch (error) {
        console.error("Error fetching notices:", error.message);
        setNotice("Notice currently unavailable");
      }
    };
    fetchNotices();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setIsFormVisible(currentScrollPosition < lastScrollPosition);
      setLastScrollPosition(currentScrollPosition);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  return (
    <div id="carousel-section" className="relative w-full h-full  overflow-hidden">
      <div className="relative w-full h-64 sm:h-80 md:h-96 lg:h-screen overflow-hidden">
        {/* <div className="absolute top-0 left-0 w-full bg-white text-yellow-500 p-2 text-lg flex items-center font-bold z-20">
          <div className="flex items-center gap-4">
            <img src={blinkingIcon} alt="Blinking Icon" className="w-10 animate-blink" />
            <div className="whitespace-nowrap animate-scroll-right-left">
              {notice || "Loading notice..."}
            </div>
          </div>
        </div> */}
        <video className="w-full h-full object-cover" autoPlay loop muted>
          <source src={videoSource} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div
        className={`relative bg-white p-4 mt-16 rounded-lg shadow-lg border border-gray-300 z-30 w-full sm:absolute sm:top-8 sm:right-4 sm:w-96 transition-transform duration-700 ${
          isFormVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-20"
        }`}
      >
         <form onSubmit={handleSubmit}>
    <div className="mb-4">
      <input
        type="text"
        name="parent_name"
        value={formData.parent_name}
        onChange={handleChange}
        placeholder="Parent Name"
        className="border rounded w-full p-2 text-sm focus:ring-blue-400"
        required
      />
      {errors.parent_name && <p className="text-red-500 text-xs mt-1">{errors.parent_name}</p>}
    </div>
    <div className="mb-4">
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        className="border rounded w-full p-2 text-sm focus:ring-blue-400"
        required
      />
      {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
    </div>
    <div className="mb-4">
      <input
        type="tel"
        name="mobile"
        value={formData.mobile}
        onChange={handleChange}
        placeholder="Mobile"
        className="border rounded w-full p-2 text-sm focus:ring-blue-400"
        required
      />
      {errors.mobile && <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>}
    </div>
    <div className="mb-4">
      <input
        type="text"
        name="city"
        value={formData.city}
        onChange={handleChange}
        placeholder="City"
        className="border rounded w-full p-2 text-sm focus:ring-blue-400"
        required
      />
      {errors.city && <p className="text-red-500 text-xs mt-1">{errors.city}</p>}
    </div>
    <textarea
      name="query"
      value={formData.query}
      onChange={handleChange}
      placeholder="Query"
      rows="4"
      className="mb-4 border rounded w-full p-2 text-sm focus:ring-blue-400"
      required
    />
    <button type="submit" className="bg-[#8A2465] text-white px-6 py-2 rounded hover:bg-[#7a1e58]">
      Submit
    </button>
  </form>
      </div>
    </div>
  );
};

export default Carousel;
