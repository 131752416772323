import React from 'react'
import press1 from '../assets/press1.jpg';
import press2 from '../assets/press2.jpg'

const Press = () => {
  return (
    <>
      <div className="container-fluid top-header-sec">
        <div className="container breadcrumb-div">
          <h2>Press</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 py-3">
        <div className="row press-pic">
            <div className="col-md-6">
              <img src={press1} alt="school in paper" className="img-fluid press" />
            </div>
            <div className="col-md-6">
              <img src={press2} alt="school Event in paper" className="img-fluid press" />
            </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Press
