import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eventimg from "../assets/our potential.JPG"; // Ensure this path is correct

const OurPotential = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Our Potential
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 flex justify-between px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex w-full gap-10" 
            data-aos="fade-up"
            style={{ display: "flex", gap: "10%", alignItems: "center" }}
          >
            {/* Image Section */}
            <div className="w-1/2 flex-shrink-0 ">
              <img
                src={eventimg}
                alt="Our Potential"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-1/2 flex flex-col">
              <h3 
                className="transition-colors duration-300 hover:text-blue-600 text-lg font-semibold"
                data-aos="fade-up"
              >
                Our Potential
              </h3>

              <p data-aos="fade-up" className="my-4">
                Total personality development is one area where particular care is taken to provide for the intrinsic needs of the individual child, who is able to participate in some event/activity of his/her choice and gets the ideal kind of exposure. To realize these objectives, a whole lot of curricular activities are organized – dance, music (vocal as well as instrumental), drama, fancy dress, recitation, debates, skits, and many others.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPotential;