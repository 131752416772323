import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import viceprincipal from "../assets/viceprincipal.jpg";
import princi from "../assets/princi.png";

const PrincipleDesk = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section */}
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Principal’s Desk
          </h2>
        </div>
      </div>

      {/* Content Section */}
      <div className="container-fluid my-5">
        <div className="container py-5 px-[10%]">
          {/* Principal's Message */}
          <div className="flex flex-col md:flex-row gap-[10%] items-center mb-10" data-aos="fade-up">
            <div className="md:w-1/4 text-center">
              <img
                src={princi}
                alt="Principal"
                className="w-full h-auto rounded-lg transition-transform duration-300 hover:scale-105"
              />
              <p className="mt-3 font-semibold text-gray-700">
                TOMMY THOMAS <br /> (Principal)
              </p>
            </div>
            <div className="md:w-3/4">
              <h5 className="text-2xl text-[#13366F] mb-4 transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">
                Principal's Message
              </h5>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                May the child blossom into a genius, a masterpiece and a bright star, who will bring honour and fame to
                home, school, community, country and the entire world. The ethos of St. Joseph’s Mission School emanates
                a meaningful atmosphere making the learners self-reliant, competent and humanitarian individuals.
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                St. Joseph’s Mission School provides quality education with modern techniques, innovative learning yet
                keeping the foundation strong. We want to instill self-belief in a child and develop a love of learning.
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                Every child has a talent, and we must elevate that talent to its maximum potential. Therefore, at St.
                Joseph’s Mission School, we focus on the overall development of the child.
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                We aspire to craft socially conscious and responsible individuals who like to serve the society. St.
                Joseph’s Mission School aims to be an institution that empowers each child with a sound foundation of
                knowledge and life skills.
              </p>
              <p className="text-gray-700 leading-relaxed" data-aos="fade-up">
                We welcome you to join our family to explore a new way of learning.
              </p>
            </div>
          </div>

          {/* Vice Principal's Message */}
          <div className="flex flex-col-reverse md:flex-row gap-[10%] items-center" data-aos="fade-up">
            <div className="md:w-3/4">
              <h5 className="text-2xl text-[#13366F] mb-4 transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">
                Vice Principal's Message
              </h5>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                Dear Students, Parents, and Guardians,
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                It is with great pleasure and enthusiasm that I extend a warm welcome to each of you to our school’s
                website. As the Vice Principal, I am honored to be part of a dynamic educational community dedicated to
                nurturing the growth and development of our students.
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                At St. Joseph’s Mission School, we are committed to providing a supportive and inclusive learning
                environment where every individual is empowered to achieve their fullest potential. Our dedicated team of
                educators strives to foster academic excellence, critical thinking skills, and character development in
                our students.
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                Through a rich and diverse range of academic programs, extracurricular activities, and community
                initiatives, we aim to inspire a love for learning and instill the values of integrity, respect, and
                responsibility in our students.
              </p>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                As Vice Principal, my door is always open, and I welcome your feedback, questions, and suggestions.
                Together, let us continue to work collaboratively to create an enriching and fulfilling educational
                experience for all members of our school community.
              </p>
              <p className="text-gray-700 leading-relaxed" data-aos="fade-up">
                Thank you for your ongoing support and partnership.
              </p>
            </div>
            <div className="md:w-1/4 text-center">
              <img
                src={viceprincipal}
                alt="Vice Principal"
                className="w-full h-auto rounded-lg transition-transform duration-300 hover:scale-105"
              />
              <p className="mt-3 font-semibold text-gray-700">
                Mrs. Molly Tommy <br /> (Vice Principal)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipleDesk;
