import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Photos = () => {
  const [images, setImages] = useState([]);

  // Fetch gallery data from API
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with duration and trigger once

    const fetchGallery = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-gallery`);

        const galleryData = response.data.map((item) => ({
          src: item.imgpath,
          alt: item.imgname || "Gallery Image",
          text: item.imgtext || "", // Include imgtext
        }));
        setImages(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      }
    };

    fetchGallery();
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-center text-3xl text-[#13366F]">Photos</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 py-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6" data-aos="fade-up">
            {images.length > 0 ? (
              images.map((image, index) => (
                <div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-lg relative group transition-transform duration-300"
                  data-aos="zoom-in"
                >
                  {/* Image */}
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-64 object-cover transform group-hover:scale-110 transition-transform duration-500 ease-in-out"
                  />
                  {/* Overlay with text */}
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-500 flex items-center justify-center">
                    <p className="text-white text-lg font-semibold opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                      {image.alt}
                    </p>
                  </div>
                  {/* Image Text */}
                  <p className="text-center mt-2 text-gray-600">{image.text}</p>
                </div>
              ))
            ) : (
              <p className="text-center col-span-full text-gray-500">No images available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Photos;
