import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import eventimg from "../assets/student club.JPG";

const StudentsClub = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F]">Students Clubs</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5">
          <div className="row carrierrow">
            <div className="col-md-6" data-aos="fade-right">
              <div className="img-ele-container">
                <img 
                  src={eventimg} 
                  alt="Students Clubs" 
                  className="mt-5 w-2/3 h-auto transition-transform duration-300 hover:scale-105" 
                />
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-left">
              <h3 className="text-2xl text-[#13366F] transition-colors duration-300 hover:text-blue-600">School Overview</h3>

              <p data-aos="fade-up">
                In order to widen the experiential horizons of the students and
                to fan their creativity, the school encourages a number of club
                activities. Each student is provided with a gamut of options to
                choose from –
              </p>
              <ol className="student-club-list" data-aos="fade-up">
                <li className="transition-colors duration-300 hover:text-blue-600">I.T. Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Eco Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Astronomy Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Photography Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Sports Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Drama Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Art Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Science Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Political Science Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Cultural Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Language Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Career Club</li>
                <li className="transition-colors duration-300 hover:text-blue-600">Creative Art Club</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsClub;