import axios from 'axios';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/log-error`,{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        error: {
          message: error.message,
          config: error.config,
          response: error.response,
        },
      }),
    }).catch((err) => console.error("Failed to log axios error:", err));
    return Promise.reject(error);
  }
);
