import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import smtcls from "../assets/smart classes.JPG";

const SmartClasses = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="w-full mt-10 bg-gray-100 py-6" data-aos="fade-up">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Smart Classes
          </h2>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className="max-w-7xl mx-auto my-5 px-4 lg:px-10">
          {/* Row container with flex */}
          <div
            className="flex flex-col lg:flex-row gap-5 lg:gap-10 items-center"
            data-aos="fade-up"
          >
            {/* Image Section */}
            <div className="w-full lg:w-1/3 flex-shrink-0">
              <img
                src={smtcls}
                alt="Smart Classes"
                className="w-full h-auto mt-5 transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-full lg:w-1/2 flex flex-col">
              <h3
                className="transition-colors duration-300 hover:text-blue-600 text-lg font-semibold"
                data-aos="fade-up"
              >
                Smart Classes
              </h3>

              <p data-aos="fade-up" className="my-4">
                Our classrooms, which have a seating capacity of 30, are spacious and
                well-ventilated with ample natural and artificial lighting.
              </p>
              <p data-aos="fade-up" className="my-4">
                Ergonomically designed furniture, green glass & white boards,
                smart boards, and teaching aids are artistically displayed,
                facilitating the learning process.
              </p>
              <p data-aos="fade-up" className="my-4">
                St. Joseph’s Mission School has a tie-up with Educomp, which
                provides smart boards and other multimedia teaching aids through
                which students can watch videos related to the subjects as well
                as give answers through wireless remotes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartClasses;