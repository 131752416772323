import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const CheckTransferCertificate = () => {
  const [scholarNumber, setScholarNumber] = useState('');
  const [studentName, setStudentName] = useState('');
  const [data, setData] = useState([]); 
  const [filteredData, setFilteredData] = useState([]); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}tc`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching TC records:', error);
      }
    };
    

    fetchData();
    AOS.init({ duration: 1000 }); 
  }, []);

  
  useEffect(() => {
    if (scholarNumber || studentName) {
      const filtered = data.filter((item) => {
        const scholarMatch = scholarNumber
          ? item.scholar_number.toString().includes(scholarNumber)
          : true;
        const nameMatch = studentName
          ? item.name.toLowerCase().includes(studentName.toLowerCase())
          : true;

        return scholarMatch && nameMatch;
      });

      setFilteredData(filtered);
    } else {
      setFilteredData([]); 
    }
  }, [scholarNumber, studentName, data]);

  return (
    <div className="container-fluid section-tcrecord">
      <div className="tc-records" data-aos="fade-up">
        <h3 className="text-center text-2xl mt-10  text-[#13366F]">
          TRANSFER CERTIFICATE RECORDS - ST. JOSEPH'S MISSION SCHOOL, BIJURI
        </h3>
        <div className="search-section" data-aos="fade-up">
          <div className="form-group">
            <label htmlFor="scholarNumber">Scholar Number* :</label>
            <input
              type="text"
              id="scholarNumber"
              placeholder="Enter Scholar Number"
              value={scholarNumber}
              onChange={(e) => setScholarNumber(e.target.value)}
              className="border rounded p-2"
            />
          </div>
          <div className="form-group">
            <label htmlFor="studentName">Student Name :</label>
            <input
              type="text"
              id="studentName"
              placeholder="Enter Student Name"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
              className="border rounded p-2"
            />
          </div>
        </div>
        <table className="min-w-full border-collapse border border-gray-300" data-aos="fade-up">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Scholar Number</th>
              <th className="border border-gray-300 p-2">Student Name</th>
              <th className="border border-gray-300 p-2">View Link</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 transition-colors duration-300">
                  <td className="border border-gray-300 p-2">{item.scholar_number}</td>
                  <td className="border border-gray-300 p-2">{item.name}</td>
                  <td className="border border-gray-300 p-2">
                  <a
  href={`${process.env.REACT_APP_API_URL}/uploads/${item.file_path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline transition-colors duration-300"
                    >
                      View Certificate
                    </a>
                  </td>
                </tr>
              ))
            ) : scholarNumber || studentName ? (
              <tr>
                <td colSpan="3" className="text-center border border-gray-300 p-2">
                  No records found
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CheckTransferCertificate;
