import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import campus from "../assets/campus.jpg";
import eveentimg from "../assets/buses.png";
import campus1 from "../assets/interactive learning.jpg";

const SchoolAmenity = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            School Amenities
          </h2>
        </div>
      </div>

      <div className="container-fluid why-jms-section">
        <div className="container my-5">
          <div className="container aboutcarrier">
            <div className="row carrierrow" data-aos="fade-up">
              <div className="col-md-6">
                <div className="img-ele-container">
                  <img 
                    src={campus} 
                    alt="facility" 
                    className="transition-transform duration-300 hover:scale-105" 
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">The Campus</h3>
                <p data-aos="fade-up">
                  For the children, St. Joseph’s Mission School represents a place where they feel a sense of belongingness. Classrooms, libraries, laboratories, and activity rooms are laid out in varying forms to inspire interaction and active participation. Well-ventilated and well-lit classrooms with large windows are designed for comfort in varying weather conditions of Central India. From the splash pool to vegetable gardening and to the creation of the open courtyard, pathways and green spaces for each cluster of classes, the building design is meant to evoke sensory and interactive experiences.
                </p>
              </div>
            </div>
          </div>
          
          <div className="container aboutcarrier">
            <div className="row carrierrow" data-aos="fade-up">
              <div className="col-md-6">
                <h3 className="transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">Transportation</h3>
                <p data-aos="fade-up">
                  The school has got its own fleet of buses to provide better transport facilities to the students. School’s as well as contractor’s buses ply to different areas and cover almost the entire Anuppur, M.P., and selected areas of Manendragarh, C.G. The total area of the playground is more than 10,000 sq. mtrs. The school provides the best of modern infrastructure and facilities for students to pursue education without distraction.
                </p>
              </div>
              <div className="col-md-6">
                <div className="img-ele-container">
                  <img 
                    src={eveentimg} 
                    alt="Parental Involvement" 
                    className="transition-transform duration-300 hover:scale-105" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container aboutcarrier">
            <div className="row carrierrow" data-aos="fade-up">
              <div className="col-md-6">
                <div className="img-ele-container">
                  <img 
                    src={campus1} 
                    alt="Community" 
                    className="transition-transform duration-300 hover:scale-105" 
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h3 className="transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">A Place of Belonging and Interactive Learning</h3>
                <p data-aos="fade-up">
                  For the children, St. Joseph’s Mission School represents a place where they feel a sense of belongingness. Classrooms, libraries, laboratories, and activity rooms are laid out in varying forms to inspire interaction and active participation. Well-ventilated and well-lit classrooms with large windows are designed for comfort in varying weather conditions of Central India. From the splash pool to vegetable gardening and to the creation of the open courtyard, pathways and green spaces for each cluster of classes, the building design is meant to evoke sensory and interactive experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolAmenity;