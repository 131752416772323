import React from 'react'

const Videos = () => {
  return (
    <>
      <div className="container-fluid top-header-sec">
        <div className="container breadcrumb-div">
          <h2>Videos</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 p-3 text-center"></div>
        </div>
    </>
  )
}

export default Videos
