import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const AdminAddPictures = () => {
    const [data, setData] = useState([]); 
    const [imgname, setImgname] = useState("");
    const [imgtext,setImgtext]=useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imgname", imgname);
    formData.append("imgtext", imgtext);
    formData.append("img_file", file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/post-gallery`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Data Saved Successfully",
          text: "You have successfully saved data!",
        });
        
        fetchGalleryImages();
        setImgname("");
        setImgtext("");
        setFile(null);
        fileInputRef.current.value = null;
      } else {
        throw new Error("Unexpected response status");
      }
  
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "There was an issue submitting the data. Please try again.",
      });
    }
  };

  // Fetch the gallery images from the backend
  const fetchGalleryImages = async () => {
    
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-gallery`, { withCredentials: true });
      console.log(response.data);
      
      setData(response.data);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch images", error);
    }
  };

  
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this image? This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
      });
  
      if (result.isConfirmed) {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}gallery-delete/${id}`, {
          withCredentials: true,
        });
  
        Swal.fire({
          icon: "success",
          title: "Image Deleted",
          text: "You have successfully deleted the image!",
        });
        fetchGalleryImages();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Deletion Failed",
        text: "There was an issue deleting the image. Please try again.",
      });
    }
  };
  
  useEffect(() => {
    fetchGalleryImages();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-8">
              <div className="card p-3">
                <h2 className="text-center">All Images of Gallery</h2>
                <table className="table">
                  <thead>
                    <th>Image</th>
                    <th> Name</th>
                    <th>About Image</th>
                    <th>Uploaded Date</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((image) => (
                        <tr key={image.id}>
                          <td><img src={image.imgpath} alt={image.imgname} width="100" height="50" />
                          </td>
                          <td>{image.imgname}</td>
                          <td>{image.imgtext}</td>
                          <td>{new Date(image.created_at).toLocaleDateString()}</td>
                          <td>
                            <span
                              className="btn"
                              onClick={() => handleDelete(image.id)}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No images found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3">
                <h2 className="text-center mb-2">Add Image in Gallery</h2>
                <hr/>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="imgname">Image Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Form Header"
                      name="imgname"
                      value={imgname}
                      required
                      onChange={(e) => setImgname(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">Upload Image</label>
                    <input
                      type="file"
                      className="form-control"
                      required
                      ref={fileInputRef}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div className="mb-3">
                      <textarea
                        rows={3}
                        cols={30}
                        type="text"
                        placeholder="About Image"
                        className="form-control rounded-5"
                        name="imgtext"
                        value={imgtext}
                      onChange={(e) => setImgtext(e.target.value)}
                      />
                    </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "50px",
                    }}
                  >
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminAddPictures
