import React from 'react'

const ErrorPage = () => {
  return (
    <div className='container mt-5'>
      <div className='col-md-4 offset-4 my-4'>
        <div className='align-items-center text-center'>
            <span className="Error-text">404</span>
            <h3 className='text-danger p-4 my-4 fs-2'>This Functionality is currently not avilable!</h3>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
