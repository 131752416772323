import React from 'react';
import { Link } from 'react-router-dom';
import footerlogo from "../assets/footer-logo.png";

const Footer = () => {
  return (
    <div>
      <div className="container-fluid mt-5 bg-[#E7DFDF]">
        <div className='container p-4'>
          <footer className="text-center text-lg-start">
            <section>
              <div className='row'>
                <div className='col-md-4 menu-container'>
                  <div className="header-logo">
                    <Link to="/"><img src={footerlogo} alt="logo" /></Link>
                  </div>
                  <p className="text-sm">
                    At St. Joseph’s Mission School, we welcome all young learners to be a part of our thriving educational community. Together, let’s embark on a journey of knowledge, character, and growth.
                  </p>
                </div>
                <div className='col-md-4 menu-container'>
                  <h6 className="text-lg font-semibold">Quick Link</h6>
                  <hr className="bg-black h-0.5" />
                  <div className='menu-item flex flex-col justify-around'>
                    <div className="mb-2"><Link to="/school-overview" className="hover:text-[rgb(217,214,156)] cursor-pointer">School Overview</Link></div>
                    <div className="mb-2"><Link to="/admission-process" className="hover:text-[rgb(217,214,156)] cursor-pointer">Admission Process</Link></div>
                    <div className="mb-2"><Link to="/co-scholastic-activities" className="hover:text-[rgb(217,214,156)] cursor-pointer">Co-Scholastic Activities</Link></div>
                    <div className="mb-2"><Link to="/curriculam" className="hover:text-[rgb(217,214,156)] cursor-pointer">Curriculum</Link></div>
                    <div className="mb-2"><Link to="/our-potential" className="hover:text-[rgb(217,214,156)] cursor-pointer">Our Potential</Link></div>
                  </div>
                </div>
                <div className='col-md-4 menu-container'>
                  <h6 className="text-lg font-semibold">Contact Us</h6>
                  <hr className="bg-black h-0.5" />
                  <div className='menu-item'>
                    <p><b>Address:</b> St. Joseph’s Mission School Bijuri, Post- Bijuri, Distt- Anuppur, Madhya Pradesh PIN- 484440</p>
                    <p><b>Contact No:</b></p>
                    <p>Mr. Shashi: 8878484220</p>
                    <p>Mr. Tapas: 8435279350</p>
                    <p>Mr. Alok: 9827718042</p>
                    <p><b>Email:</b> stjmsb@gmail.com</p>
                  </div>
                </div>
              </div>
            </section>
          </footer>
        </div>
        <div className="text-center p-3 w-full bg-[rgba(0,0,0,0.2)]">
          ©Copyright 2024 SJMS. Designed & Developed by INS.
        </div>
      </div>
    </div>
  )
}

export default Footer;