import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eventimg from "../assets/sports.JPG"; // Ensure this path is correct

const Sports = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Sports
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 flex justify-between px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex w-full gap-10" 
            data-aos="fade-up"
            style={{ display: "flex", gap: "10%", alignItems: "center" }}
          >
            {/* Image Section */}
            <div className="w-1/2 flex-shrink-0 ">
              <img
                src={eventimg}
                alt="Sports"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-1/2 flex flex-col">
              <h3 
                className="transition-colors duration-300 hover:text-blue-600 text-lg font-semibold"
                data-aos="fade-up"
              >
                Sports is Education too…
              </h3>

              <p data-aos="fade-up" className="my-4">
                Sports make a child realize the importance of DISCIPLINE through a regular exercise regime and a healthy eating habit. It also helps in building TEAMWORK, as in the real world, one is expected to know when to step up when the going gets tough and how to play the role of a reinforce when at the sidelines. All games and sports in our school inculcate the essence of being a good team player in our children. Another important value that our children learn through sports is RESPECT for one and all. It is through sports that our students learn to pick up cues handed by their coach and also accept suggestions from their teammates.
              </p>

              <p data-aos="fade-up">
                As a formal center of learning, the opportunity to bring education and sports together is one of the main areas of focus at St. Joseph’s Mission School. Along with the direct benefits to the students through improved physical skills, health, self-confidence & self-esteem benefits are also associated with skilled and regular exercise.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sports;