import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const AdminHome = () => {
  const [scholarNumber, setScholarNumber] = useState("");
  const [name, setName] = useState("");
  const [selectedFile, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const token = localStorage.getItem("authToken");

  const getAllTcRecords = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}tc`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "Fetch Failed",
        text: error.response?.data?.message || "Error fetching records.",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('scholarNumber', scholarNumber);
    formData.append('name', name);
    formData.append('certificateFile', selectedFile);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}transfercertificate`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`, // Include token in the request
          },
          withCredentials: true, // Necessary if token is stored in cookies
        }
      );
      
      
      // Success alert after successful submission
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.data.message || 'Transfer Certificate added successfully!',
      });
  
      // Clear the form fields after success
      setScholarNumber('');
      setName('');
      setFile(null);
      fileInputRef.current.value = '';
  
      // Refresh the list of records
      getAllTcRecords();
  
    } catch (error) {
      // Error handling
      Swal.fire({
        icon: 'error',
        title: 'Submission Failed',
        text: error.response?.data?.message || 'There was an issue submitting the data.',
      });
      console.error('Error:', error.response.data);
    }
  };
  
  
  

  

  useEffect(() => {
    if (token) {
      getAllTcRecords();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Authentication Required",
        text: "Please log in to view and manage records.",
      });
    }
  }, []);

  const deleteTcByScholarNo = async (scholarNumber) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/deleteTc/${scholarNumber}`
      );
      
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Deleted Successfully",
          text: response.data.message,
        });
        getAllTcRecords();
      } else {
        Swal.fire({
          icon: "error",
          title: "Deletion Failed",
          text: "Unexpected response from the server.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Deletion Failed",
        text: "There was an issue deleting the record. Please try again.",
      });
    }
  };
  const handleDelete = (scholarNumber) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTcByScholarNo(scholarNumber);
      }
    });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = data.filter(
      (item) =>
        item.scholar_number.toString().includes(query) ||
        item.name.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page after search
  };

  const handleEntriesChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when entries per page changes
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const displayedData = filteredData.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );
  

  useEffect(() => {
    getAllTcRecords();
  }, []);

  return (
    <div className="container-fluid ">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="card p-3">
              <div className="card-title text-center">
                <h3>Transfer Certificates</h3>
              </div>
              
              <div className="card-body">
                
              <div className="d-flex justify-content-between">
                <div >
                  <label>Show 
                  <select
                    className="mx-2 p-1"
                    value={entriesPerPage}
                    onChange={handleEntriesChange}
                    style={{ width: "50px", display: "inline-block" }}
                  >
                    <span><i class="fa-solid fa-caret-down"></i></span>
                    <option value={5}>5<i class="fa-solid fa-caret-down"></i></option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                  </select>
                  
                   entries</label>
                </div>
                <div>
                  Search : 
                  <input
                    type="text"
                    className="form-control mx-2"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ width: "100px", display: "inline-block" }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <table className="table">
                  <thead>
                    <th>Scholar No</th>
                    <th>Student Name</th>
                    <th>Transfer Certificate</th>
                    <th>Date</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {displayedData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.scholar_number}</td>
                        <td>{item.name}</td>
                        <td>
                        <a
  href={`${process.env.REACT_APP_API_URL}uploads/${item.file_path}`}
  target="_blank"
  rel="noopener noreferrer"
>
   {item.file_path}
                          </a>
                        </td>
                        <td>{item.created_at}</td>
                        <td>
                          <span
                            className="btn"
                            onClick={() => handleDelete(item.scholar_number)
                            }
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
                <div className="d-flex justify-content-between">
                  <span>
                    Showing {currentPage * entriesPerPage - entriesPerPage + 1}{" "}
                    to {Math.min(currentPage * entriesPerPage, filteredData.length)}{" "}
                    of {filteredData.length} entries
                  </span>
                  <div>
                    <button
                    className="btn btn-secondary mx-2"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      Previous
                    </button>
                    <button
                    className="btn btn-secondary mx-2"
                      disabled={currentPage === totalPages}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-3">
              <h3 className="mb-3 card-title">Add Transfer Certificate</h3>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="">Scholar Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Scholar Number"
                      name="scholarNumber"
                      value={scholarNumber}
                      required
                      onChange={(e) => setScholarNumber(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      name="name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">Transfer Certificate File(PDF)</label>
                    <input
                      type="file"
                      className="form-control"
                      required
                      ref={fileInputRef}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "50px",
                    }}
                  >
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
