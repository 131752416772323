import React, { useState, useEffect } from 'react';
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const RegistrationFromNewSession = () => {
  const [data, setData] = useState([]);

  const getAllRegisterForms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-registrationform`,
        { withCredentials: true }
      );
      
      setData(response.data);  // Store the response data
    } catch (error) {
      console.log("There was an error fetching the forms!", error);
    }
  };

  useEffect(() => {
    getAllRegisterForms();
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F]">Registration Form</h2>
        </div>
      </div>
      <div className="container-fluid registration-container">
        <div className="container my-5 text-center registration-form">
          <div className='registration-head mb-5'>
            <h2 className="text-2xl text-[#13366F]" data-aos="fade-up">REGISTRATION FORMS</h2>
          </div>
          <ul className="admission-list">
            {data.map((regdata, index) => (
              <li key={index} data-aos="fade-up" className="transition-colors duration-300 hover:text-blue-600">
                <strong>{regdata.formheader}</strong>
                <a 
                  className="admission-btn transition-transform duration-300 hover:scale-105" 
                  href={`${process.env.REACT_APP_API_URL}/uploads/${regdata.form_path}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default RegistrationFromNewSession;