import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Whyjms.css";
import readingAbility from "../assets/whystjosep.JPG";

const WhyJms = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Why St. Joseph Mission School
          </h2>
        </div>
      </div>

      <div className="container-fluid why-jms-section">
        <div className="container my-5 px-5 lg:px-0">
          <h6
            className="why-jms-title text-2xl text-[#13366F] transition-colors duration-300 hover:text-blue-600"
            data-aos="fade-right"
          >
            Because St. Joseph’s Mission School…
          </h6>
          <p className="why-jms-intro" data-aos="fade-up">
            is an effort in a direction that would create change with and
            through education . . .
          </p>
          <p className="why-jms-intro" data-aos="fade-up">A change in . . .</p>

          <div className="flex flex-col lg:flex-row gap-5 items-center">
            {/* Image Section */}
            <div
              className="w-full lg:w-1/2"
              data-aos="zoom-in"
            >
              <img
                src={readingAbility}
                alt="Reading img"
                className="w-full h-auto transition-transform  border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-full lg:w-1/2">
              <ul className="why-jms-list">
                {[
                  {
                    title: "Our attitude towards children",
                    description:
                      "Our understanding of children’s growth and development.",
                  },
                  {
                    title: "Learning abilities",
                    description:
                      "The way we perceive and evaluate children’s learning abilities.",
                  },
                  {
                    title: "Creative development",
                    description:
                      "The way we provide opportunities for children to develop and express their creative selves.",
                  },
                  {
                    title: "Learning environments",
                    description:
                      "The way we prepare ourselves to organize learning environments for children.",
                  },
                  {
                    title: "Partnership with children, teachers, and parents",
                    description:
                      "The way we partner with them to better understand children and their growing-up needs.",
                  },
                  {
                    title: "Modern facilities",
                    description:
                      "The total area of the playground is more than 10,000 sq. meters. The school provides the best of modern infrastructure and facilities for students to pursue education without distraction.",
                  },
                ].map((item, index) => (
                  <li
                    key={index}
                    data-aos="fade-up"
                    className="transition-transform duration-300 hover:scale-105 my-2"
                  >
                    <strong>{item.title}</strong> - {item.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyJms;
