// import React from 'react'
// import { Navigate } from 'react-router-dom'

// const ProtectedRoute = ({ isLoggedIn, children }) => {
//     if(!isLoggedIn){
//         return <Navigate to='/login' />
//     }
//   return children;
   
// }

// export default ProtectedRoute

// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isLoggedIn, children }) => {
  // If user is not logged in, redirect to login page
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // If user is logged in, render the child component
  return children;
};

export default ProtectedRoute;
