import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eveentimg from "../assets/learning spaces.jpg";

const LearningSpace = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Learning Spaces
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 flex justify-between px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex w-full gap-10" 
            data-aos="fade-up"
            style={{ display: "flex", gap: "10%", alignItems: "center" }}
          >
            {/* Image Section */}
            <div className="w-1/2 flex-shrink-0">
              <img
                src={eveentimg}
                alt="Learning Spaces"
                className="w-full h-auto mt-5 border rounded-lg transition-transform duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-1/2 flex flex-col">
              <h3 
                className="transition-colors duration-300 hover:text-blue-600 text-lg font-semibold"
                data-aos="fade-up"
              >
                Learning Spaces
              </h3>

              <p data-aos="fade-up" className="my-4">
                Well-stocked racks with books that suit the taste of all,
                reference books, text books, encyclopedias, journals, and
                magazines add to the value of the library. The silent, serene
                atmosphere in the reading room gives optimum scope for the
                learner’s quest for knowledge and information.
              </p>

              <p data-aos="fade-up" className="my-4">
                The well-equipped science and computer laboratories provide all
                that is required for the learner to gain practical knowledge.
                Physics, Chemistry, Biology, and Computer Science labs are
                beehives of in-depth study activities. High-tech multimedia
                laboratories with internet facilities are indeed a boon for the
                scholars.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningSpace;
