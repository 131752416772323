import React, { useState } from "react";
import logo from "../assets/logo.png"; // Replace with your actual logo path
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [dropdownTimers, setDropdownTimers] = useState({});

  const dropdownItems = {
    "About Us": [
      { name: "School Overview", link: "/school-overview" },
      { name: "Why St. Joseph Mission School", link: "/why-st-joseph-mission-school" },
      { name: "Principal's Desk", link: "/principalmessage" },
      { name: "School Amenities", link: "/school-amenities" },
      { name: "Facilitators", link: "/facilitator" },
      { name: "Teachers-Pupil Ratio", link: "/teacher-pupil-ratio" },
    ],
    "Mandatory Public Disclosure": [
      { name: "General Information", link: "/general-info" },
      { name: "Documents and Information", link: "/mandatory-public-disclosure#documents-information" },
      { name: "Result and Academics", link: "/mandatory-public-disclosure#result-academics" },
      { name: "Teaching Staff", link: "/mandatory-public-disclosure#staff" },
      { name: "School Infrastructure", link: "/mandatory-public-disclosure#school-infrastructure" },
    ],
    Academics: [
      { name: "Academic Overview", link: "/academic-overview" },
      { name: "Teaching Methodology", link: "/teaching-methodlogy" },
      { name: "Curriculum", link: "/curriculam" },
      { name: "Textbook", link: "/text-book" },
      { name: "Smart Classes", link: "/smart-classes" },
      { name: "Learning Spaces", link: "/learning-spaces" },
    ],
    "Beyond Academics": [
      { name: "Overview", link: "/beyond-academics-overview" },
      { name: "Expeditions", link: "/expeditions" },
      { name: "Our Potential", link: "/our-potential" },
      { name: "Sports", link: "/sports" },
      { name: "Hostel Facility", link: "/hostel-facility" },
      { name: "Co-Scholastic Activity", link: "/co-scholastic-activities" },
      { name: "Students Club", link: "/students-club" },
    ],
    Admission: [
      { name: "Admission Process", link: "/admission-process" },
      { name: "Registration Forms", link: "/registration-from-new-session" },
      { name: "School Fees", link: "/school-fees" },
      { name: "Transfer Certificate Records", link: "/check-transfer-certificate" },
    ],
    Gallery: [
      { name: "Photos", link: "/photos" },
      { name: "Videos", link: "/videos" },
      { name: "Press", link: "/press" },
    ],
  };

  const handleDropdownToggle = (index) => {
    if (dropdownTimers[index]) {
      clearTimeout(dropdownTimers[index]);
      setDropdownTimers((prev) => ({ ...prev, [index]: null }));
    }
    setDropdownOpen(index);
  };

  const handleMouseLeave = (index) => {
    const timer = setTimeout(() => {
      setDropdownOpen(null);
    }, 300);
    setDropdownTimers((prev) => ({ ...prev, [index]: timer }));
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  return (
    <div className="w-full fixed top-0 left-0 z-50">
      {/* Top Blue Strip */}
      <nav className="flex items-center justify-between bg-[#3E4095] p-2 text-white">
        {/* Logo (mobile view only) */}
        <div className="md:hidden flex-grow text-center">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-10 inline-block" />
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className="md:hidden text-white"
        >
          {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>

        {/* Desktop Links */}
        <div className="hidden md:flex space-x-6 mr-8 ml-auto">
          <a
            href="/check-transfer-certificate"
            className="hover:underline px-2 rounded-md bg-blue-300 font-bold"
          >
            Transfer Certificate
          </a>
          <a
            href="/login"
            className="hover:underline bg-blue-300 rounded-md px-2 font-bold"
          >
            Login
          </a>
        </div>
      </nav>

      {/* Mobile Slide-in Menu */}
      <div
        className={`fixed top-0 right-0 h-full  bg-white w-[90%] z-40 shadow-lg transform ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform md:hidden`}
      >
        <div className="flex flex-col p-4 mt-10 pt-12 space-y-4">
          <a
            href="/check-transfer-certificate"
            className="text-gray-700 hover:text-[#3E4095] font-bold"
            onClick={handleMenuItemClick}
          >
            Transfer Certificate
          </a>
          <a
            href="/login"
            className="text-gray-700 hover:text-[#3E4095] font-bold"
            onClick={handleMenuItemClick}
          >
            Login
          </a>
          {Object.keys(dropdownItems).map((section, index) => (
            <div key={index}>
              <button
                onClick={() => handleDropdownToggle(index)}
                className="w-full text-left text-gray-700 hover:text-[#3E4095] flex justify-between items-center py-2 font-bold"
              >
                {section}
                <span>{dropdownOpen === index ? <FaChevronUp /> : <FaChevronDown />}</span>
              </button>

              {/* Dropdown Content */}
              {dropdownOpen === index && (
                <ul className="pl-4">
                  {dropdownItems[section].map((item, subIndex) => (
                    <li key={subIndex} className="py-1 hover:text-[#3E4095]">
                      <a href={item.link} onClick={handleMenuItemClick}>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* White Background Navbar for Desktop */}
      <nav className="hidden md:flex items-center justify-between bg-white shadow-md p-2">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-20 w-auto" />
          </Link>
        </div>

        <ul className="flex gap-8 mr-10">
          {Object.keys(dropdownItems).map((section, index) => (
            <li
              key={index}
              className="relative group"
              onMouseEnter={() => handleDropdownToggle(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <p className="text-gray-700 hover:text-[#3E4095] font-bold flex items-center">
                {section}
                <span className="ml-2">
                  <FaChevronDown
                    className={`w-4 h-4 transition-transform transform ${
                      dropdownOpen === index ? "rotate-180" : ""
                    }`}
                  />
                </span>
              </p>
              {dropdownOpen === index && (
                <div className="absolute bg-white border rounded-lg shadow-lg mt-2 w-64">
                  <ul className="py-2">
                    {dropdownItems[section].map((item, subIndex) => (
                      <li
                        key={subIndex}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <a href={item.link} className="block w-full h-full">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}

          <a href="/contact">
            <li className="font-bold text-gray-700">Contact</li>
          </a>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
