import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import eventimg from "../assets/faculty.JPG";

const Facilitator = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section */}
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Facilitators
          </h2>
        </div>
      </div>

      {/* Content Section */}
      <div className="container-fluid my-5">
        <div className="container py-5 px-[10%]">
          <div className="flex flex-col md:flex-row gap-[10%] items-center" data-aos="fade-up">
            {/* Image Section */}
            <div className="md:w-1/2">
              <div className="overflow-hidden rounded-lg">
                <img
                  src={eventimg}
                  alt="Facilitators"
                  className="w-full h-auto transition-transform duration-300 hover:scale-105"
                />
              </div>
            </div>

            {/* Text Section */}
            <div className="md:w-1/2">
              <h3 className="text-2xl text-[#13366F] mb-4 transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">
                Facilitators
              </h3>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                We understand that a teacher introduces the learners to success.
                St. Joseph’s Mission School has an excellent team of
                professional and highly qualified faculty who are determined to
                enlighten the lives of our learners. Passionate, creative, and
                innovative individuals with excellent communication skills and
                fire in their belly, the teachers are partners in the progress
                of learners and the institution at large.
              </p>
              <p className="text-gray-700 leading-relaxed" data-aos="fade-up">
                Regular updates help them keep pace with the new trends and
                teaching methodologies like ‘Smart Board technology’ by
                Extramarks, to aid the learning of children and promote Higher
                Order Thinking Skills. The staff attends regular seminars and
                workshops on professional development, computer-aided learning,
                and create software packages specially designed to support the
                curriculum. The teachers are facilitators who guide our learners
                through discovery with inspiration and motivation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilitator;
