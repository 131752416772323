import React, { useState, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const AdminAddRegisteration = () => {
  const [data, setData] = useState([]);
  const [header, setHeader] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const getAllRegisterForms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}get-registrationform`,
        { withCredentials: true }
      );

      console.log(response.data); // Check the response structure
      setData(response.data);
    } catch (error) {
      console.log("There was an error fetching the forms!", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("header", header);
    formData.append("registrationfile", file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/registrationform`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Data Saved Successful",
        text: "You have successfully saved data!",
      });
      getAllRegisterForms();
      setHeader("");
      setFile(null);
      fileInputRef.current.value = null;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "There was an issue submitting the data. Please try again.",
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this form? This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/delete-registrationform/${id}`,
            { withCredentials: true }
          );

          getAllRegisterForms(); // Refresh the table after deletion
          Swal.fire({
            icon: "success",
            title: "Form Deleted",
            text: "You have successfully deleted the form!",
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Deletion Failed",
            text: "There was an issue deleting the form. Please try again.",
          });
        }
      }
    });
  };

  useEffect(() => {
    getAllRegisterForms();
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-8">
              <div className="card p-3">
                <h2 className="text-center">All Avilable Forms</h2>
                <table className="table">
                  <thead>
                    <th>SNo</th>
                    <th>Form Header</th>
                    <th>Form File</th>

                    <th>Action</th>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.formheader}</td>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_API_URL}/uploads/${item.form_path}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.form_path}
                          </a>
                        </td>
                        <td>
                          <span
                            className="btn"
                            onClick={() => handleDelete(item.id)}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3">
                <h2 className="text-center mb-2">Add new form </h2>
                <hr />
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="header">Registration form Header</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Form Header"
                      name="header"
                      value={header}
                      required
                      onChange={(e) => setHeader(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">Upload Registration form(PDF)</label>
                    <input
                      type="file"
                      className="form-control"
                      required
                      ref={fileInputRef}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "50px",
                    }}
                  >
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddRegisteration;
