import React, { useState } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import './AdminNav.css'; // Import custom styles
import logoimg from '../assets/footer-logo.png';
import axios from 'axios';

const AdminNav = ({ isLoggedIn, setIsLoggedIn }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}logout`,
        {},
        { withCredentials: true }
      );
      localStorage.removeItem('authToken');
      setIsLoggedIn(false);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout', error);
    }
  };
  
  return (
    <div className="admin-layout ">
      {/* Sidebar */}
      <div className="sidebar mt-10">
        <div className="header-logo">
          <Link to="/">
            <img src={logoimg} alt="logo" />
          </Link>
        </div>
        <h3 className="sidebar-title">Admin Panel</h3>
        <ul className="sidebar-list">
          <li>
            <Link to="/admin/admin-home" className="sidebar-link">
              Transfer Certificate
            </Link>
          </li>
          <li>
            <Link to="/admin/admin-registration" className="sidebar-link">
              Registration Form
            </Link>
          </li>
          <li>
            <Link to="/admin/admin-add-images" className="sidebar-link">
              Gallery
            </Link>
          </li>
          <li>
            <Link to="/admin/admin-add-notice" className="sidebar-link">
              Notification / Marque
            </Link>
          </li>

          <li>
            <Link to="/admin/admin-Contact_details" className="sidebar-link">
              Contact details
            </Link>
          </li>

          <li>
            <Link to="/admin/admin-Achievers" className="sidebar-link">
              Achievers
            </Link>
          </li>
        </ul>
      </div>

      {/* Main content */}
      <div className="main-content mt-9">
        {/* Header */}
        <div className="header">
          <Link to="/">
            <button className="btn-back" onClick={handleLogout}>Back to Website</button>
          </Link>
          <div className="profile-menu">
            <button
              className="btn-profile"
              onClick={() => setShowProfileMenu(!showProfileMenu)}
            >
              <i className="fa-solid fa-user"></i> {/* User Profile Icon */}
            </button>
            {showProfileMenu && (
              <div className="profile-dropdown">
                <button className="btn-logout" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Page content */}
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminNav;
