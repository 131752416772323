window.addEventListener("error", (event) => {
  fetch(`${process.env.REACT_APP_API_URL}/api/log-error`,{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        error: {
          message: event.message,
          source: event.filename,
          lineno: event.lineno,
          colno: event.colno,
          stack: event.error?.stack,
        },
      }),
    }).catch((err) => console.error("Failed to log error:", err));
  });
  
  window.addEventListener("unhandledrejection", (event) => {
    fetch("http://localhost:5000/api/log-error", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ error: { message: event.reason?.message || "Unknown rejection", stack: event.reason?.stack } }),
    }).catch((err) => console.error("Failed to log unhandled rejection:", err));
  });
  