import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import hostel1 from "../assets/hostel.webp";
import hostel2 from "../assets/hostel2.webp";
import safensecure from "../assets/home-security.png";
import cultureEducational from "../assets/culture.png";
import healtheating from "../assets/healthy-eating.png";
import parentteacher from "../assets/tutor.png";
import comfortable from "../assets/comfortable_dormitories.png";
import recredentalspace from "../assets/facilityicon.png";
import healthcare from "../assets/healthcare_.png";
import studyhour from "../assets/study_hour.png";

const HostelFacility = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F]">Hostel Facility</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5">
          <div className="row hostel-intro-row">
            <div className="col-md-6 hostel-intro-text" data-aos="fade-right">
              <h3>Providing a home away from home</h3>
              <p>
                At St. Joseph’s Mission School, we understand that a nurturing
                and secure environment is essential for a child’s holistic
                development. We take pride in offering exceptional hostel
                facilities to our students, ensuring that they feel comfortable,
                cared for, and motivated to excel in their academic journey.
              </p>
            </div>
            <div className="col-md-6 hostel-img-container" data-aos="fade-left">
              <img src={hostel1} alt="Hostel" className="w-2/3 h-auto transition-transform duration-300 hover:scale-105" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 hostel-section-title" data-aos="fade-up">
              <h4>Why Choose St. Joseph's Mission School Hostel?</h4>
            </div>
            <div className="col-md-4 hostel-features-column" data-aos="fade-up">
              <h6><span className="focus-span">Holistic </span>Growth Opportunities​</h6>
              <p>
                Our hostel experience provides ample opportunities for students
                to develop leadership skills, build friendships, and foster a
                sense of independence.
              </p>
              <h6><span className="focus-span">Focus on</span> Character Development</h6>
              <p>
                Beyond academics, we emphasize character development,
                encouraging students to imbibe values of respect,
                responsibility, and empathy.
              </p>
            </div>
            <div className="col-md-4 hostel-img-column" data-aos="fade-up">
              <img src={hostel2} alt="Hostel img" className="w-2/3 h-auto transition-transform duration-300 hover:scale-105" />
            </div>
            <div className="col-md-4 hostel-features-column" data-aos="fade-up">
              <h6><span className="focus-span">Experien</span>ced Hostel Staff</h6>
              <p>
                Our hostel staff members are experienced, caring, and dedicated
                to creating a warm and supportive environment for every student.
              </p>
              <h6><span className="focus-span">Strong Se</span>nse of Belonging</h6>
              <p>
                St. Joseph’s Mission School hostel offers a second home for our
                students, ensuring a sense of belonging and comfort while they
                pursue their dreams.
              </p>
            </div>
            <div className="col-md-12 hostel-section-title mt-5" data-aos="fade-up">
              <h4>Our Hostel Facilities</h4>
            </div>
            <div className="hostel-facilities">
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={safensecure} alt="Safe and Secure Accommodation" className="transition-transform duration-300 hover:scale-105" />
                <p>Safe and Secure Accommodation</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={cultureEducational} alt="Cultural Programs" className="transition-transform duration-300 hover:scale-105" />
                <p>Cultural and Educational Programs</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={healtheating} alt="Healthy Meals" className="transition-transform duration-300 hover:scale-105" />
                <p>Healthy and Nutritious Meals</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={parentteacher} alt="Parent-Teacher Interaction" className="transition-transform duration-300 hover:scale-105" />
                <p>Regular Parent-Teacher Interaction</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={comfortable} alt="Comfortable Dormitories" className="transition-transform duration-300 hover:scale-105" />
                <p>Comfortable Dormitories</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={recredentalspace} alt="Recreational Spaces" className="transition-transform duration-300 hover:scale-105" />
                <p>Recreational Spaces</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={healthcare} alt="Healthcare Support" className="transition-transform duration-300 hover:scale-105" />
                <p>Healthcare Support</p>
              </div>
              <div className="col-md-3 hostel-facility-item" data-aos="fade-up">
                <img src={studyhour} alt="Study Hours" className="transition-transform duration-300 hover:scale-105" />
                <p>Supervised Study Hours</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostelFacility;