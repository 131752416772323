
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import aboutus from "../assets/aboutus.jpg";
import facility from "../assets/our facility.JPG";
import parental from "../assets/parentalinvol.jpg";
import Community from "../assets/community outreach.JPG";
import facilityicon from "../assets/facilityicon.png";
import parentalicon from "../assets/parentalicon.png";
import Communityicon from "../assets/communityicon.png";
import abouticon from "../assets/about-us.png";
import "./Overview.css";

const Overview = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl font-bold" data-aos="fade-down">School Overview</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 text-center">
          <h3 className="text-4xl text-blue-900" data-aos="fade-up">
            Welcome to St. Joseph's Mission School
          </h3>
          <p className="mt-4" data-aos="fade-up">
            A place where knowledge meets character, and learning becomes a
            lifelong journey. Since our establishment, we have been committed to
            providing a holistic educational experience that empowers students
            to excel academically, develop a strong moral compass, and embrace
            their unique talents and abilities.
          </p>

          <div className="container aboutcarrier">
            <div className="row carrierrow">
              <div className="col-md-6" data-aos="fade-right">
                <div className="img-ele-container">
                  <img src={aboutus} alt="about us" className="rounded-lg shadow-lg" />
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-left">
                <img src={abouticon} alt="About icon" className="h-16 w-auto" />
                <h3 className="text-2xl font-semibold mt-2">About Us</h3>
                <p className="mt-2">
                  St. Joseph’s Mission School was founded with a vision to
                  impart quality education while nurturing the values of
                  respect, integrity, and compassion. Our school is situated in
                  a serene and conducive environment that fosters creativity,
                  critical thinking, and a love for learning. We take pride in
                  our dedicated team of experienced educators who are passionate
                  about shaping young minds and guiding them towards excellence.
                </p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {[
                {
                  icon: "far fa-handshake",
                  title: "Academic Excellence",
                  description:
                    "We strive for academic brilliance, encouraging every student to reach their full potential. Our well-rounded curriculum is designed to instill a thirst for knowledge and a passion for lifelong learning.",
                },
                {
                  icon: "far fa-building",
                  title: "Character Building​",
                  description:
                    "We are committed to fostering strong character traits such as honesty, kindness, and responsibility. Our focus on character education helps students grow into compassionate and responsible global citizens.",
                },
                {
                  icon: "far fa-snowflake",
                  title: "Respect for Diversity",
                  description:
                    "We celebrate the richness of diversity and promote an inclusive environment where students from various backgrounds feel welcome, valued, and respected.",
                },
                {
                  icon: "far fa-chart-bar",
                  title: "Co-Curricular Activities",
                  description:
                    "We emphasize the importance of a balanced education by offering a wide range of co-curricular activities , including sports, arts, music, and more. These activities help students discover their passions and talents beyond the classroom.",
                },
              ].map((item, index) => (
                <div className="col-md-3" key={index} data-aos="fade-up">
                  <div className="card overview-card p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <div className="flex justify-center mb-2">
                      <i className={`${item.icon} fa-3x`} aria-hidden="true"></i>
                    </div>
                    <h4 className="text-xl font-bold">{item.title}</h4>
                    <p className="mt-2">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="container aboutcarrier">
            <div className="row carrierrow">
              <div className="col-md-6" data-aos="fade-right">
                <div className="img-ele-container">
                  <img src={facility} alt="facility" className="rounded-lg shadow-lg" />
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-left">
                <img src={facilityicon} alt="icon" className="h-16 w-auto" />
                <h3 className="text-2xl font-semibold mt-2">Our Facilities</h3>
                <p className="mt-2">
                  St. Joseph’s Mission School boasts state-of-the-art facilities
                  that provide a conducive learning environment for our
                  students. Our classrooms are equipped with modern teaching
                  aids to enhance the learning experience. Additionally, we have
                  well-equipped science and computer laboratories, a
                  well-stocked library, and sports facilities that encourage
                  physical fitness and teamwork.
                </p>
              </div>
            </div>
          </div>

          <div className="container aboutcarrier">
            <div className="row carrierrow">
              <div className="col-md-6" data-aos="fade-up">
                <img src={parentalicon} alt="icon" className="h-16 w-auto" />
                <h3 className="text-2xl font-semibold mt-2">Parental Involvement</h3>
                <p className="mt-2">
                  We believe in the power of a strong partnership between the
                  school and parents. We encourage parents to actively
                  participate in their child’s education journey and work
                  together to ensure their overall growth and well-being.
                </p>
              </div>
              <div className="col-md-6" data-aos="fade-up">
                <div className="img-ele-container">
                  <img src={parental} alt="Parental Involvement" className="rounded-lg shadow-lg" />
                </div>
              </div>
            </div>
          </div>

          <div className="container aboutcarrier">
            <div className="row carrierrow">
              <div className="col-md-6" data-aos="fade-right">
                <div className="img-ele-container">
                  <img src={Community} alt="Community" className="rounded-lg shadow-lg" />
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-left">
                <img src={Communityicon} alt="icon" className="h-16 w-auto" />
                <h3 className="text-2xl font-semibold mt-2">Community Outreach</h3>
                <p className="mt-2">
                  As part of our commitment to social responsibility, we engage
                  in various community outreach programs. Through these
                  initiatives, our students learn the importance of giving back
                  to society and making a positive impact on the lives of others.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;