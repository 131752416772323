import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import eventimg from "../assets/teacher-pupil-ratio.jpg";

const TeacherPupilRatio = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header Section */}
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Teacher-Pupil Ratio
          </h2>
        </div>
      </div>

      {/* Content Section */}
      <div className="container-fluid my-5">
        <div className="container py-5 px-[10%]">
          <div className="flex flex-col md:flex-row gap-[10%] items-center" data-aos="fade-up">
            {/* Image Section */}
            <div className="md:w-1/2">
              <div className="overflow-hidden rounded-lg">
                <img
                  src={eventimg}
                  alt="Teacher-Pupil Ratio"
                  className="w-full h-auto transition-transform duration-300 hover:scale-105"
                />
              </div>
            </div>

            {/* Text Section */}
            <div className="md:w-1/2">
              <h3 className="text-2xl text-[#13366F] mb-4 transition-colors duration-300 hover:text-blue-600" data-aos="fade-up">
                Teacher-Pupil Ratio
              </h3>
              <p className="text-gray-700 mb-4 leading-relaxed" data-aos="fade-up">
                In order to address the educational and interpersonal needs of
                different age groups, the school has been divided into five
                class groups. These include Nursery and Kindergarten, Classes I
                to V, Classes VI – VIII, Classes IX-X, and Classes XI-XII. The
                school curriculum and infrastructure facilities have been
                designed to meet the needs of the students of each specific
                class group.
              </p>
              <p className="text-gray-700 leading-relaxed" data-aos="fade-up">
                Each section has 28 to 30 students. With a host of activity and
                subject teachers, the total teacher-pupil ratio is maintained at
                1:15. To make learning more challenging and meaningful,
                scholarly pursuits in the senior classes will be supported by
                tutorial guidance, laboratory work, field excursions,
                participation in co-curricular and sports activities. The search
                for individual and group excellence will be facilitated through
                participation in inter-house and inter-school activities
                organized in the school.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherPupilRatio;
