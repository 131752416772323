import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import eventimg from "../assets/admission_proces.jpg";

const AdmissionProcess = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F]">Admission Process</h2>
        </div>
      </div>
      <div className="container-fluid admission-container">
        <div className="container admission-content">
          <div className="row admission-row">
            <div className="col-md-6 admission-img-col" data-aos="fade-right">
              <div className="admission-img-container">
                <img 
                  src={eventimg} 
                  alt="Admission Process" 
                  className="admission-img transition-transform duration-300 hover:scale-105" 
                  
                />
              </div>
            </div>
            <div className="col-md-6 admission-text-col" data-aos="fade-left">
              <h3 className="admission-heading text-2xl text-[#13366F] transition-colors duration-300 hover:text-blue-600">Admission Process</h3>
              <p className="admission-paragraph" data-aos="fade-up">
                The brook of St. Joseph’s Mission School continues to murmur and meander 
                through valleys, reaching new shores of achievements and maturing into a 
                full-grown river. We remain on ascendancy with new feathers in our cap on a 
                regular basis. Keeping up with the latest development in the field of education, 
                we have designed a learner-friendly curriculum which takes care of the different 
                needs of all age groups.
              </p>
              <p className="admission-paragraph" data-aos="fade-up">
                The academic session for classes I to XII commences from April, for which the 
                registration for new students opens in February.
              </p>
            </div>
          </div>
          <div className="admission-age-criteria" data-aos="fade-up">
            <h4 className="admission-age-title text-xl text-[#13366F]">Age Criteria for Admission</h4>
            <ul className="admission-age-list">
              <li><strong>Nursery</strong> – 3 Years as on 1st April of the current Academic Session.</li>
              <li><strong>Pre-Primary</strong> – 4 Years as on 1st April of the current Academic Session.</li>
              <li><strong>Class I</strong> – 5 Years as on 1st April of the current Academic Session.</li>
            </ul>
          </div>
          <div className="admission-streams" data-aos="fade-up">
            <h4 className="admission-streams-title text-xl text-[#13366F]">Available Streams:</h4>
            <p>Science, Commerce, and Humanities. The subject combinations available are:</p>
            <ul className="admission-streams-list">
              <li><strong>Science:</strong> English / Mathematics / Biology / Physics / Chemistry / PE / Economics / C++ / Psychology</li>
              <li><strong>Commerce:</strong> English / Mathematics / Economics / Accountancy / Business Studies/ PE / IP</li>
              <li><strong>Humanities:</strong> English / Psychology / History / PE/ Economics / Sociology / Fine Arts / Political Science</li>
            </ul>
            <p className="admission-paragraph" data-aos="fade-up">
              To uphold the values of comprehensive education, we believe in facilitating meaningful learning 
              or discovery in the child’s mind.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmissionProcess;