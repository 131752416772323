import React from 'react';
import axios from 'axios';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    this.logError(error, errorInfo);
  }

  logError = async (error, errorInfo) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/log-error`, {
        error: error.toString(),
        errorInfo: errorInfo.componentStack,
      });
    } catch (loggingError) {
      console.error('Error while logging frontend error:', loggingError);
    }
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
