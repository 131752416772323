import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContactDetails = () => {
  const [contactData, setContactData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('newest'); // default to "newest first"

  // Fetch data when the component mounts
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/api/contact-form`)
   // Replace with your actual API URL
      .then((response) => {
        setContactData(response.data); // Set the data to state
        setFilteredData(response.data); // Set the initial filtered data to the complete data
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((err) => {
        setError('An error occurred while fetching data.'); // Handle any errors
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  // Handle search functionality
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    const filtered = contactData.filter((data) =>
      data.parent_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      data.email.toLowerCase().includes(event.target.value.toLowerCase())
    );

    setFilteredData(filtered);
  };

  // Handle sorting functionality
  const handleSort = (event) => {
    const order = event.target.value;
    setSortOrder(order);

    let sortedData = [...filteredData];
    if (order === 'newest') {
      sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Newest first
    } else {
      sortedData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)); // Oldest first
    }

    setFilteredData(sortedData);
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading state while data is being fetched
  }

  if (error) {
    return <div>{error}</div>; // Display error if there's an issue fetching the data
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Contact Details</h1>

      {/* Search and Filter Options */}
      <div className="flex justify-between mb-6">
        <input
          type="text"
          className="px-4 py-2 border rounded-lg w-1/3"
          placeholder="Search by Parent Name or Email"
          value={searchTerm}
          onChange={handleSearch}
        />
        <select
          className="px-4 py-2 border rounded-lg"
          value={sortOrder}
          onChange={handleSort}
        >
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="px-6 py-3 text-left">ID</th>
              <th className="px-6 py-3 text-left">Parent Name</th>
              <th className="px-6 py-3 text-left">Mobile</th>
              <th className="px-6 py-3 text-left">Email</th>
              <th className="px-6 py-3 text-left">City</th>
              <th className="px-6 py-3 text-left">Query</th>
              <th className="px-6 py-3 text-left">Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((data) => (
              <tr key={data.id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="px-6 py-4 text-gray-700">{data.id}</td>
                <td className="px-6 py-4 text-gray-700">{data.parent_name}</td>
                <td className="px-6 py-4 text-gray-700">{data.mobile}</td>
                <td className="px-6 py-4 text-gray-700">{data.email}</td>
                <td className="px-6 py-4 text-gray-700">{data.city}</td>
                
                <td className="px-6 py-4 text-gray-700">{data.query}</td>
                <td className="px-6 py-4 text-gray-700">
                  {new Date(data.submitted_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactDetails;
