import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const AdminAddMarque = () => {
  const [data, setData] = useState([]);
  const [notice, setNotice] = useState("");

  // Function to submit a new notice
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the notice data to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/post-notice`,
        { notice },
        { withCredentials: true }
      );
      
      // Show success alert using Swal
      Swal.fire({
        icon: "success",
        title: "Notice Added",
        text: "The notice has been successfully added!",
      });
      // Clear the input field and refresh the notice list
      setNotice("");
      fetchNotices();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to Add Notice",
        text: "There was an error while adding the notice. Please try again.",
      });
      console.error("Error submitting notice:", error);
    }
  };

  // Function to fetch all notices
  const fetchNotices = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-notice`, {
        withCredentials: true,
      });
      setData(response.data); // Update the notice data state
    } catch (error) {
      console.error("Error fetching notices:", error);
    }
  };

  // Function to delete a notice
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this notice? This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_URL}delete-notice/${id}`, {
            withCredentials: true,
          });
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "The notice has been deleted.",
          });
          fetchNotices(); // Refresh notices after deletion
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Failed to Delete",
            text: "There was an error while deleting the notice. Please try again.",
          });
          console.error("Error deleting notice:", error);
        }
      }
    });
  };

  // Fetch notices when the component mounts
  useEffect(() => {
    fetchNotices();
  }, []);


  return (
    <div>
      <div className="container-fluid">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-7">
              <div className="card p-3">
                <h2 className="text-center">All Avilable Notice</h2>
                <table className="table">
                  <thead>
                    <th>SNo</th>
                    <th>Notice</th>
                    <th>Date</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr>
                        <td>{index+1}</td>
                        <td>{item.notice}</td>
                        <td>{item.created_date}</td>
                        <td>
                          <span
                            className="btn"
                            onClick={() => handleDelete(item.id)}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card p-3">
                <h2 className="text-center mb-2">Add Notice Form </h2>
                <hr />
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="header">Add New Notice </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write notice..."
                      name="notice"
                      value={notice}
                      required
                      onChange={(e) => setNotice(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "50px",
                    }}
                  >
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddMarque;
