import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eventimg from "../assets/expeditions.jpg"; // Ensure this path is correct

const Expeditions = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Expeditions
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 flex justify-between px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex w-full gap-10" 
            data-aos="fade-up"
            style={{ display: "flex", gap: "10%", alignItems: "center" }}
          >
            {/* Image Section */}
            <div className="w-1/2 flex-shrink-0 ">
              <img
                src={eventimg}
                alt="Expeditions"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-1/2 flex flex-col">
              <h3 
                className="transition-colors duration-300 hover:text-blue-600 text-lg font-semibold"
                data-aos="fade-up"
              >
                Hands-On Expeditions
              </h3>

              <p data-aos="fade-up" className="my-4">
                Learning at St. Joseph’s Mission School extends beyond the classroom walls. Students regularly engage in outdoor expeditions linked to their academic projects and themes. These hands-on experiences help students explore real-world systems, develop life skills, and build a deep understanding of people, places, and cultures.
              </p>

              <p data-aos="fade-up">
                “To understand is to discover, or reconstruct by discovery. Such experiences shape individuals capable of creativity, not just repetition.”
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expeditions;