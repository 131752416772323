import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MandatoryPublicDisclosure = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const GeneralInformationData = [
    {
      information: "Name of the School",
      gdetails: "St. Joseph’s Mission School",
    },
    { information: "Affiliation No.", gdetails: "1030257" },
    { information: "School Code", gdetails: "50231" },
    { information: "UDISE Code", gdetails: "23470803514" },
    {
      information: "Address",
      gdetails:
        "St. Joseph’s Mission School Bijuri, Post-Bijuri, District-Anuppur Madhya Pradesh, 484440",
    },
    { information: "School Email Id", gdetails: "stjmsb@gmail.com" },
    {
      information: "Contact Details",
      gdetails:
        "Mr. Tomy Thomas. A (Principal) : 9754495369 Mrs. Molly Tomy (Vice-Principal) : 8435564630 Mr. Alok Tigga (P.R.O) – 9827718042 Mr. Tapas Mukherjee (P.R.O) – 8435279350 Mr. Shashi.N.Karandikar (Clerk) – 88784842",url: "public/documents/fee-structure.pdf"
    },
  ];
  const documentsData = [
    {
      title:
        "Copies Of Affiliation /Upgradation Letter And Recent Extension Of Affiliation If Any",url: "/documents/AFFILIATION-LETTER-2015-2020.pdf"
    },
    {
      title:
        "Copies Of Societies/Trust/Company Registration/Renewal Certificate As Applicable",url: "/documents/society-Registration.pdf"
    },
    {
      title:
        "Copy Of No Objection Certificate (NOC) Issued, If Applicable By The State Govt /UT",url: "/documents/STATE-NOC.pdf"
    },
    {
      title:
        "Copies Of Recognition Certificate Under RTE Act, 2009, And It’s Renewal If Applicable",url: "/documents/STATE-NOC.pdf"
    },
    {
      title:
        "Copy Of Valid Building Safety Certificate As Per The National Building Code",url: "/documents/building-safety-certificate-6.pdf"
    },
    {
      title:
        "Copy Of Valid Fire Safety Certificate Issued By The Competent Authority",url: "/documents/fire-approval.pdf"
    },
    { title: "Copies Of Valid Water, Health And Sanitation Certificates",url: "/documents/Safe-drinking-water-certificate.pdf" },
    { title: "Copies Of CBSE Certificates",url: "/documents/cbse-letter.pdf" },
    { title: "Copies Of Self Certificates",url: "/documents/Self-certificate.pdf" },
    { title: "Copies Of Land Certificates",url: "/documents/land-certificate-4.pdf" },
  ];

  const academicsData = [
    { title: "Fee Structure Of The School",url: "/documents/fee-structure.pdf" },
    { title: "Annual Academic Calendar",url: "/documents/academic-calendar.pdf" },
    { title: "List Of School Management Committee (SMC)",url: "/documents/SMC.pdf" },
    { title: "List Of Parents Teachers Association (PTA) Members",url: "/documents/SMC.pdf" },
    {
      title:
        "Last Three-Year Result Of The Board Examination As Per Applicability",url: "/documents/Result.pdf"
    },
  ];

  const staffData = [
    { role: "HOS", count: "01" },
    { role: "Principal", count: "01" },
    { role: "Vice Principal", count: "01" },
    { role: "Librarian", count: "01" },
    { role: "Total no. Of Teachers", count: "67" },
    { role: "PGT", count: "11" },
    { role: "TGT", count: "27" },
    { role: "PRT", count: "21" },
    { role: "NTT", count: "08" },
    { role: "PET", count: "02" },
    {role: "Details of Special Educator", count:"01"},
    {role: "Details of Counsellor and Wellness Teacher", count:"01"},
  ];

  const infrastructureData = [
    {
      title: "Total Campus Area Of The School (In Square Mtr)",
      details: "10117.11 Sq. Mtr",
    },
    {
      title: "No. And Size Of The Class Rooms (In Sq Mtr)",
      details: "No.80, Size- 7×6 Sq. Mtr",
    },
    {
      title: "No. And Size Of Laboratories Including Computer Labs (In Sq Mtr)",
      details: "No. 8, Size- 14.28 X 6.60 Sq. Mtr",
    },
    { title: "Internet Facility (Y/N)", details: "Yes" },
    { title: "No. Of Girls Toilets", details: "35" },
    { title: "No. Of Boys Toilets", details: "30" },
    { title: "Link Of Youtube Video Of The Inspection Of School Covering The Infrastructure Of The School",  details: (
      <a
        className="watchlink"
        href="https://www.youtube.com/watch?feature=shared&v=k-7Fzov5RIA"
        target="_blank"
        rel="noopener noreferrer"
      >
        Watch Video
      </a>
    ),},
  ];
  return (
    <>
      <div className="container-fluid top-header-sec">
        <div className="container breadcrumb-div">
          <h2> Mandatory Public Disclosure </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="general-info-container">
          {/* Section A: General Information */}
          <h2 id="general-information" className="section-header">
            <span>A. General Information</span>
          </h2>
          <table className="gen-info-table">
            <thead>
              <tr>
                <th>Sl.No.</th>
                <th>Information</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {GeneralInformationData.map((geninfo, index) => (
                <tr key={index}>
                <td>{index + 1}</td>
                <td>{geninfo.information}</td>
                <td>{geninfo.gdetails}</td>
                </tr>
              ))}
              
            </tbody>
          </table>

          {/* Section B: Documents and Information */}
          <h2 id="documents-information" className="section-header"><span>B. Documents and Information</span></h2>
          <table className="info-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Documents / Information</th>
                <th>View Documents</th>
              </tr>
            </thead>
            <tbody>
              {documentsData.map((doc, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{doc.title}</td>
                  <td>
                  <a href={doc.url} className="view-link" target="_blank" rel="noopener noreferrer"> View
                  </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Section C: Result and Academics */}
          <h2 id="result-academics" className="section-header"><span>C. Result and Academics</span></h2>
          <table className="info-table">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Documents / Information</th>
                <th>View Documents</th>
              </tr>
            </thead>
            <tbody>
              {academicsData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>
                  <a href={item.url} className="view-link" target="_blank" rel="noopener noreferrer"> View
                  </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Section D: Staff (Teaching) */}
          <h2 id="staff" className="section-header"><span>D. Teaching Staff</span></h2>
          <table className="info-table">
            <tbody>
              {staffData.map((staff, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{staff.role}</td>
                  <td>{staff.count}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Section E: School Infrastructure */}
          <h2 id="school-infrastructure" className="section-header">
            <span>E. School Infrastructure</span>
          </h2>
          <table className="info-table">
            <tbody>
              {infrastructureData.map((infra, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{infra.title}</td>
                  <td>{infra.details}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MandatoryPublicDisclosure;
