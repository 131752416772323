import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const GeneralInfo = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="general-info-container mt-5" data-aos="fade-up">
      <h2 className="general-info-header transition-colors duration-300 hover:text-blue-600">
        A. General Information
      </h2>
      <table className="general-info-table">
        <thead>
          <tr>
            <th className="transition-colors duration-300 hover:text-blue-600">Sl.No.</th>
            <th className="transition-colors duration-300 hover:text-blue-600">Information</th>
            <th className="transition-colors duration-300 hover:text-blue-600">Details</th>
          </tr>
        </thead>
        <tbody>
          {[
            { sl: 1, info: "Name of the School", details: "St. Joseph’s Mission School" },
            { sl: 2, info: "Affiliation No.", details: "1030257" },
            { sl: 3, info: "School Code", details: "50231" },
            { sl: 4, info: "UDISE Code", details: "23470803514" },
            { sl: 5, info: "Address", details: "St. Joseph’s Mission School Bijuri, Post-Bijuri, District-Anuppur Madhya Pradesh, 484440" },
            { sl: 6, info: "School Email Id", details: "stjmsb@gmail.com" },
            { sl: 7, info: "Contact Details", details: (
              <>
                Mr. Tomy Thomas. A (Principal): 9754495369<br />
                Mrs. Molly Tomy (Vice-Principal): 8435564630<br />
                Mr. Alok Tigga (P.R.O) – 9827718042<br />
                Mr. Tapas Mukherjee (P.R.O) – 8435279350<br />
                Mr. Shashi.N.Karandikar (Clerk) – 88784842
              </>
            )}
          ].map(row => (
            <tr key={row.sl} className="transition-transform duration-300 hover:bg-gray-100">
              <td>{row.sl}</td>
              <td>{row.info}</td>
              <td>{row.details}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GeneralInfo;