import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS CSS
import "../components/Home.css"
import Carousel from "../HomePageComponents/Crausol";
import AboutstJosep from "../HomePageComponents/AboutstJosep";
import Achievers from "../HomePageComponents/Achievers";
import WhatsSetsupart from "../HomePageComponents/WhatsSetsupart";
import image5 from "../assets/img5.jpg";
import technical from "../assets/Untitled3.png";
import verify from "../assets/finish-projct.png";
import "../components/Home.css"
const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with duration
  }, []);
  const animateCounter = (id, start, end, duration, incrementValue = 1) => {
    let current = start;
    const increment = end > start ? incrementValue : -incrementValue;
    const stepTime = Math.abs(Math.floor(duration / ((end - start) / incrementValue)));
    const element = document.getElementById(id);

    const timer = setInterval(() => {
      current += increment;
      if ((increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        current = end;
        clearInterval(timer);
      }
      element.textContent = current;
    }, stepTime);
  };

  // Use IntersectionObserver to trigger the animation when the section is in view
  useEffect(() => {
    const section = document.querySelector('.section-5');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Trigger counter animation
          animateCounter('counter1', 0, 2000, 2000, 50); // 0 to 2000, increment by 50
          animateCounter('counter2', 0, 20, 2000);       // 0 to 20, increment by 1
          animateCounter('counter3', 0, 100, 2000);      // 0 to 100, increment by 1
          animateCounter('counter4', 0, 30, 2000);       // 0 to 30, increment by 1
          observer.disconnect(); // Stop observing once counters have animated
        }
      });
    });

    observer.observe(section);
  }, []);
  
  return (
    <div className="overflow-x-hidden"> {/* Prevent horizontal scrolling */}
      <div className="mt-4">
        <Carousel />
      </div>

      <div
        className="flex flex-col items-center justify-center mt-10"
        data-aos="fade-up"
      >
        <h3 className="font-bold text-xl sm:text-xl md:text-2xl lg:text-3xl text-center">
          Welcome to St. Joseph's  Mission School, Bijuri
        </h3>
      </div>

      <div className="mt-10 text-center px-4" data-aos="fade-up">
        <p className="text-base sm:text-lg md:text-xl lg:text-xl">
          Welcome to St. Joseph’s Mission School, Bijuri, a premier educational
          institution committed to nurturing young minds and fostering holistic
          development.
        </p>
        <p
          className="text-base sm:text-lg md:text-xl lg:text-xl mt-2"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Established with a vision to provide quality education and shape
          future leaders, our school is situated in the picturesque district of
          Anuppur, Madhya Pradesh.
        </p>
      </div>

      <div>
      
      <div className="container-fluid mt-4 section-3">
        

<div className="w-full bg-[#e7dedf] flex">
      {/* Left Section */}
      <div className="w-1/3 p-4" data-aos="fade-right">
        <h2 className="text-lg font-bold mb-2">Our History</h2>
        <p>
          St. Joseph’s Mission School has a rich heritage spanning several decades. Founded with the guidance and blessings of the Missionary Fathers, our school has been a beacon of knowledge and character-building in the region. Since our inception, we have strived to instill the values of compassion, integrity, and excellence in our students.
        </p>
      </div>

      {/* Middle Section */}
      <div className="w-1/3 flex items-center justify-center" data-aos="zoom-in">
        <img
          src={image5}
          alt="School"
          className="max-w-full h-auto transition-transform border rounded-lg duration-300 ease-in-out hover:scale-105"
        />
      </div>

      {/* Right Section */}
      <div className="w-1/3 p-4" data-aos="fade-left">
        <h2 className="text-lg font-bold mb-2">Our Vision</h2>
        <p>
          At St. Joseph’s Mission School, we aspire to be a center of educational excellence, empowering each student to achieve academic proficiency, moral strength, and emotional intelligence. Our vision is to create responsible global citizens who contribute positively to society and make a difference in the world.
        </p>
      </div>
    </div>

      </div>
      <div className="container-fluid section-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3">
              <div class="cards" style={{ width: "18rem;" }}>
                <div class="card-body">
                  <h5 class="card-title">Academic Excellence</h5>
                  <div className="ele-icon">
                    <i class="fa-solid fa-award fa-4x"></i>
                  </div>
                  <p class="card-text">
                    We maintain a strong focus on academic rigor, ensuring our
                    students receive a well rounded education. Our qualified and
                    dedicated faculty members are committed to fostering a love
                    for learning, encouraging curiosity, and providing
                    personalized attention to every child.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div class="cards" style={{ width: "18rem;" }}>
                <div class="card-body">
                  <h5 class="card-title">Holistic Development</h5>
                  <div className="ele-icon">
                    <i class="fa-sharp fa-solid fa-layer-group fa-4x"></i>
                  </div>
                  <p class="card-text">
                    We believe in nurturing the overall development of our
                    students. Besides academic pursuits, we offer a wide range
                    of co-curricular and extracurricular activities, including
                    sports, arts, music, and cultural events. These
                    opportunities allow our students to discover their talents,
                    develop leadership skills, and build confidence.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div class="cards" style={{ width: "18rem;" }}>
                <div class="card-body">
                  <h5 class="card-title">State-of-the-Art Facilities</h5>
                  <div className="ele-icon">
                    <i class="fa-sharp fa-solid fa-book-open-reader fa-4x"></i>
                  </div>
                  <p class="card-text">
                    Our school campus is equipped with modern amenities and
                    infrastructure to create conducive learning environment.
                    From spacious classrooms to well-equipped laboratories and a
                    well-stocked library, we ensure that our students have
                    access to the best resources for their intellectual growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="cards" style={{ width: "18rem;" }}>
                <div class="card-body">
                  <h5 class="card-title">Values and Ethics</h5>
                  <div className="ele-icon">
                    <i class="fa-solid fa-handshake fa-4x"></i>
                  </div>
                  <p class="card-text">
                    At St. Joseph’s Mission School, we place a strong emphasis
                    on inculcating moral values and ethical principles in our
                    students. Respect, empathy, and honesty form the foundation
                    of our school community, fostering an atmosphere of mutual
                    understanding and inclusivity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="cards" style={{ width: "18rem;" }}>
                <div class="card-body">
                  <h5 class="card-title">
                    Community and Social Responsibility
                  </h5>
                  <div className="ele-icon">
                    <i class="fa-solid fa-globe fa-4x"></i>
                  </div>
                  <p class="card-text">
                    We actively engage our students in various community service
                    initiatives, encouraging them to become responsible citizens
                    who contribute positively to society. Through these efforts,
                    we instill a sense of empathy and compassion, teaching our
                    students to be mindful of the needs of others.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="cards" style={{ width: "18rem;" }}>
                <div class="card-body">
                  <h5 class="card-title">Parent-Teacher Collaboration</h5>
                  <div className="ele-icon">
                    <i class="fa-solid fa-users-line fa-4x"></i>
                  </div>
                  <p class="card-text">
                    We believe that the collaboration between parents and
                    teachers is essential for a child’s overall development. We
                    encourage open communication and involvement of parents in
                    their child’s educational journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid section-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="menu-sec5">
                <div>
                  <i className="fa-duotone fa-solid fa-people-group fa-4x"></i>
                </div>
                <div className="conter-col">
                  <h3>
                    <span id="counter1">0</span>+
                  </h3>
                  <p>Students Per-Session</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="menu-sec5">
                <div>
                  <img src={verify} alt="verify" />
                </div>
                <div className="conter-col">
                  <h3>
                    <span id="counter2">0</span>+
                  </h3>
                  <p>Years of Experience</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="menu-sec5">
                <div>
                  <img src={technical} alt="technical" />
                </div>
                <div className="conter-col">
                  <h3>
                    <span id="counter3">0</span>+
                  </h3>
                  <p>Teaching Staff</p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="menu-sec5">
                <div>
                  <i className="fa-solid fa-school fa-4x"></i>
                </div>
                <div className="conter-col">
                  <h3>
                    <span id="counter4">0</span>+
                  </h3>
                  <p>Classrooms</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div data-aos="fade-up" className="px-4">
        <AboutstJosep />
        <Achievers />
        <WhatsSetsupart />
      </div>
    </div>
  );
};

export default Home;

