import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eveentimg from "../assets/Academic-overview.jpg";

const AcademicOverview = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Academic Overview
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 px-5 md:px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex flex-col lg:flex-row gap-24 items-center" 
            data-aos="fade-up"
          >
            {/* Image Section */}
            <div className="w-full lg:w-2/5">
              <img
                src={eveentimg}
                alt="Academic Overview"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-full lg:w-1/2">
              <h3 
                className="text-lg font-semibold transition-colors duration-300 hover:text-blue-600 mb-4"
                data-aos="fade-up"
              >
                Academic Overview
              </h3>

              <p data-aos="fade-up" className="my-4 text-gray-700">
                St. Joseph’s Mission School is affiliated to the Central Board
                of Secondary Education (C.B.S.E) up to the Senior Secondary
                level (Grade XII) and is recognized by D.O.E. It is a
                co-educational school from Nursery to XII. The school offers
                Medical, Non-Medical, Commerce, and Humanities streams at Senior
                Secondary level.
              </p>

              <p data-aos="fade-up" className="text-gray-700">
                A school is what its students achieve. The students have done
                exceptionally well in the C.B.S.E Board Examinations and gained
                admissions in prestigious colleges and universities across the
                globe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcademicOverview;
