import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import loginimg from "../assets/loginimage.jpg";
import axios from "axios";

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    // Check if the user is already logged in and redirect to admin
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}login`,
        { email, password },
        { withCredentials: true }
      );
  
      if (response.status === 200) {
        console.log("Login successful:", response.data);
        // Store the token in local storage
        localStorage.setItem('authToken', response.data.token);
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: "You have been successfully logged in!",
        });
        setIsLoggedIn(true);
        navigate('/admin'); // Redirect to admin page
      }
    } catch (error) {
      console.error("Login error:", error);
      Swal.fire({
        icon: "error",
        title: "Login failed",
        text: "Incorrect email or password",
      });
    }
  };
  

  return (
    <div className="container-fluid RegisterLoginSection vh-100 d-flex justify-content-center align-items-center">
      <div className="col-md-5 login-img">
        <img src={loginimg} alt="loginimg" />
      </div>
      <div className="col-md-5">
        <div className="card1 p-4">
          <h2 className="text-center">Welcome Back!</h2>
          <p className="text-center mb-2">Please enter your login details below.</p>
          <form onSubmit={handleLogin}>
            <div className="mb-4 input-group">
              <input
                type="text"
                placeholder="User  Id"
                className="form-control rounded-0"
                name="email"
                value={email}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <FontAwesomeIcon icon={faEnvelope} className="icons ms-2" />
            </div>
            <div className="mb-4 input-group">
              <input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="form-control rounded-0"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FontAwesomeIcon
                className="icons ms-2"
                icon={showPassword ? faEye : faEyeSlash}
                onClick={togglePasswordVisibility}
              />
            </div>
            <div className="d-flex justify-content-between mb-3">
              <label>
                <input type="checkbox" /> Remember me
              </label>
              <Link onClick={togglePopup}>Forgot Password?</Link>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Submit
            </button>
          </form>
          <p className="text-center mt-3">
            Don't have an account? <Link onClick={togglePopup}>Register</Link>
          </p>
        </div>
      </div>
      {showPopup && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={togglePopup}>
              &times;
            </button>
            <h4 class Name="fs-2" style={{ color: "red" }}>
              Sorry!
            </h4>
            <div className="modal-para">
              <p>Please contact your administrator.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;