import React, { useState, useEffect } from 'react';

const Preloader = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="relative flex space-x-2">
        <div className="w-3 h-3 bg-current rounded-full animate-ellipsis1"></div>
        <div className="w-3 h-3 bg-current rounded-full animate-ellipsis2"></div>
        <div className="w-3 h-3 bg-current rounded-full animate-ellipsis2"></div>
        <div className="w-3 h-3 bg-current rounded-full animate-ellipsis3"></div>
      </div>
    </div>
  );
};

const YourComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000); // Adjust the duration as needed
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div>
      <h1 className="text-center text-xl mt-8">Content Loaded!</h1>
    </div>
  );
};

export default YourComponent;
