import React, { Component } from "react";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

export class Achievers extends Component {
  state = {
    achievers: [],
  };

  componentDidMount() {
    Aos.init();
    this.fetchAchievers();

    const container = document.getElementById("achievers-container");

    this.scrollInterval = setInterval(() => {
      if (container) {
        if (container.scrollLeft + container.offsetWidth >= container.scrollWidth) {
          container.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          container.scrollBy({ left: container.clientWidth / 4, behavior: "smooth" });
        }
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.scrollInterval);
  }

  fetchAchievers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/achievers`);
      const achievers = response.data.achievers.map((achiever) => ({
        id: achiever.id,
        name: achiever.name,
        percentage: `${achiever.percentage}%`,
        rank: achiever.ranks,
        year: achiever.years,
        image: achiever.image.replace(/\\/g, "/"), // Fix path slashes
      }));
      this.setState({ achievers });
    } catch (error) {
      console.error("Error fetching achievers data:", error);
    }
  };
  

  render() {
    const { achievers } = this.state;

    return (
      <div className="flex flex-col items-center w-full p-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-6" data-aos="fade-up">
          Achievers
        </h2>
        <div
          id="achievers-container"
          className="flex overflow-x-auto md:overflow-hidden w-full sm:w-3/4 md:w-2/3 lg:w-full space-x-4 px-4"
          style={{ scrollSnapType: "x mandatory", height: "320px" }}
        >
          {achievers.concat(achievers).map((achiever, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-4/5 sm:w-1/3 md:w-1/4 lg:w-1/5 bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105"
              style={{ scrollSnapAlign: "start", height: "100%" }}
            >
              <div className="h-56 bg-gray-200">
                <img
                  src={achiever.image}
                  alt={`Achiever ${achiever.name}`}
                  className="object-cover h-full w-full"
                />
              </div>
              <div className="flex flex-col justify-between h-2/3">
                <div className="p-4 flex-grow">
                  <h2 className="text-lg font-bold">{achiever.name}</h2>
                  <p className="text-sm">{`CBSE(X) ${achiever.year}`}</p>
                  <p className="text-sm">
                    {`SCHOOL RANK - ${achiever.rank} - ${achiever.percentage}`}
                  </p>
                </div>
                <div className="h-4 bg-[#A1D7F0]"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Achievers;
