import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import eveentimg from "../assets/teaching-methology.jpg";

const TeachingMethodology = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="w-full bg-gray-100 mt-10 py-6" data-aos="fade-up">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl text-center text-[#13366F] font-bold transition-colors duration-300 hover:text-blue-600">
            Teaching Methodology
          </h2>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center" data-aos="fade-up">
            {/* Image Section */}
            <div className="w-full">
              <img
                src={eveentimg}
                alt="Teaching Methodology"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-full">
              <h3
                className="transition-colors duration-300 hover:text-blue-600 text-2xl font-semibold mb-4"
                data-aos="fade-up"
              >
                Teaching Methodology
              </h3>

              <p data-aos="fade-up" className="text-gray-600 mb-4">
                Learning is an individual process. Thus, the teaching methodology we implement is one which ensures individual attention. In doing so, the teachers, as learning facilitators, keep a constant know-how of each child’s needs and have close interaction to ensure that every child responds and is self-motivated to learn. In place of rote learning and mugging, emphasis is laid on understanding. The teaching methodology is child-friendly and learner-focused.
              </p>

              <p data-aos="fade-up" className="text-gray-600">
                Our approach encourages critical thinking, creativity, and collaboration among students, preparing them for real-world challenges. We believe that education should be engaging and relevant, fostering a love for learning that lasts a lifetime.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeachingMethodology;
