import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Logout = ({ setIsLoggedIn }) => {
  let navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        { withCredentials: true }
      );
      localStorage.removeItem("authToken");
      setIsLoggedIn(false);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout", error);
    }
  };
  return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;
