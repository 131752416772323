import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import Swal from "sweetalert2";
import Preloader from "./Preloader";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const Contact = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", contactData.name);
    formData.append("email", contactData.email);
    formData.append("phone", contactData.phone);
    formData.append("subject", contactData.subject);
    formData.append("message", contactData.message);

    if (file) {
      formData.append("certificateFile", file);
    }
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-email`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
        withCredentials: true,
      });

      Swal.fire({
        icon: "success",
        title: "Contact data sent successfully",
        text: "You have successfully sent the data.",
      });

      setContactData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      setFile(null);
      fileInputRef.current.value = null;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "There was an issue submitting data. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`contact-page ${loading ? 'faded' : ''}`}>
      <div className="contact-content">
        <div className="container-fluid top-header-sec">
          <div className="container breadcrumb-div">
            <h2 data-aos="fade-up">Contact Us</h2>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container my-5">
            <div className="row">
              <div className="col-md-4" data-aos="fade-up">
                <div className="card contact-card transition-transform transform hover:scale-105">
                  <div>
                    <i className="fa-solid fa-location-dot fa-3x"></i>
                  </div>
                  <h5 className="card-title">Address</h5>
                  <p className="card-text">
                    Bijuri, Distt- Anuppur, Madhya Pradesh
                  </p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up">
                <div className="card contact-card transition-transform transform hover:scale-105">
                  <div>
                    <i className="fa-solid fa-phone fa-3x"></i>
                  </div>
                  <h5 className="card-title">Contact Us</h5>
                  <p className="card-text">Mobile: 8878484220 / 8435279350</p> </div>
              </div>
              <div className="col-md-4" data-aos="fade-up">
                <div className="card contact-card transition-transform transform hover:scale-105">
                  <div>
                    <i className="fa-solid fa-envelope fa-3x"></i>
                  </div>
                  <h5 className="card-title">Email</h5>
                  <p className="card-text">stjmsb@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container">
            <div className="card contact-section">
              <div className="row">
                <div className="col-md-6" data-aos="fade-up">
                  <h4>Sign Up For School</h4>
                  <h3>Campus Tour</h3>
                  <p>
                    For more information or admissions inquiries, please feel
                    free to contact us.
                  </p>
                </div>
                <div className="col-md-6" data-aos="fade-up">
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control rounded-5"
                        name="name"
                        value={contactData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control rounded-5"
                        name="email"
                        value={contactData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        placeholder="Phone Number"
                        className="form-control rounded-5"
                        name="phone"
                        value={contactData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control rounded-5"
                        name="subject"
                        value={contactData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        className="form-control rounded-5"
                        name="file"
                        ref={fileInputRef}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    <div>
                      <textarea
                        rows={3}
                        cols={30}
                        type="text"
                        placeholder="Message"
                        className="form-control rounded-5"
                        name="message"
                        value={contactData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <button type="submit" className="btn bg-[#8A2465] btn-primary transition-transform transform hover:scale-105">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Preloader />}
    </div>
  );
};

export default Contact;