import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const AdminAchievers = () => {
  const [achievers, setAchievers] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    percentage: "",
    ranks: "",
    years: "",
  });
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  // Fetch achievers list
  const fetchAchievers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/achievers`);
      const achieversData = response.data.achievers.map((achiever) => ({
        id: achiever.id,
        name: achiever.name,
        percentage: `${achiever.percentage}%`,
        ranks: achiever.ranks,
        years: achiever.years,
        image: achiever.image.replace(/\\/g, "/"), // Fix path slashes
      }));
      setAchievers(achieversData);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch achievers data", "error");
    }
  };

  useEffect(() => {
    fetchAchievers();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", formData.name);
    data.append("percentage", formData.percentage);
    data.append("ranks", formData.ranks);
    data.append("years", formData.years);
    if (image) {
      data.append("image_file", image);
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/achievers`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      Swal.fire("Success", "Achiever data added successfully!", "success");
      fetchAchievers();
      setFormData({ name: "", percentage: "", ranks: "", years: "" });
      setImage(null);
      fileInputRef.current.value = null;
    } catch (error) {
      Swal.fire("Error", "Failed to add achiever data", "error");
    }
  };

  // Handle deletion
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}api/achievers/${id}`);
        Swal.fire("Deleted!", "Achiever data has been deleted.", "success");
        fetchAchievers();
      }
    } catch (error) {
      Swal.fire("Error", "Failed to delete achiever data", "error");
    }
  };

  return (
    <div className="container-fluid">
      <div className="container mt-5">
        <div className="row">
          {/* Achievers Table */}
          <div className="col-md-8">
            <div className="card p-3">
              <h2 className="text-center">Achievers List</h2>
              <table className="table">
              <thead>
                    <th>Image</th>
                    <th>Student Name</th>
                    <th>Percentage</th>
                    <th>Rank</th>
                    <th>Year</th>
                    <th>Action</th>
                  </thead>
                <tbody>
                  {achievers.length > 0 ? (
                    achievers.map((achiever) => (
                      <tr key={achiever.id}>
                        <td>
                          <img
                            src={achiever.image}
                            alt={achiever.name}
                            width="100"
                            height="50"
                          />
                        </td>
                        <td>{achiever.name}</td>
                        <td>{achiever.percentage}</td>
                        <td>{achiever.ranks}</td>
                        <td>{achiever.years}</td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(achiever.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No achievers found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Add Achiever Form */}
          <div className="col-md-4">
            <div className="card p-3">
              <h2 className="text-center mb-2">Add Achiever</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Percentage</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter percentage"
                    value={formData.percentage}
                    onChange={(e) =>
                      setFormData({ ...formData, percentage: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Rank</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter rank"
                    value={formData.ranks}
                    onChange={(e) =>
                      setFormData({ ...formData, ranks: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Year</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter year"
                    value={formData.years}
                    onChange={(e) =>
                      setFormData({ ...formData, years: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="mb-3">
                  <label>Upload Image</label>
                  <input
                    type="file"
                    className="form-control"
                    ref={fileInputRef}
                    onChange={(e) => setImage(e.target.files[0])}
                    required
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                  <button
                    type="reset"
                    className="btn btn-danger"
                    onClick={() => {
                      setFormData({ name: "", percentage: "", ranks: "", years: "" });
                      setImage(null);
                      fileInputRef.current.value = null;
                    }}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAchievers;
