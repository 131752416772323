import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eventimg from "../assets/co-scholastic-activity.jpg"; // Ensure this path is correct

const CoScholasticActivities = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid top-header-sec" data-aos="fade-up">
        <div className="container breadcrumb-div">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Co-Scholastic Activities
          </h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container my-5 flex justify-between px-[10%]">
          {/* Row container with flex */}
          <div 
            className="flex w-full gap-10" 
            data-aos="fade-up"
            style={{ display: "flex", gap: "10%", alignItems: "center" }}
          >
            {/* Image Section */}
            <div className="w-1/2 flex-shrink-0 ">
              <img
                src={eventimg}
                alt="Co-Scholastic Activities"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-1/2 flex flex-col">
              <h3 
                className="transition-colors duration-300 hover:text-blue-600 text-lg font-semibold"
                data-aos="fade-up"
              >
                Co-Scholastic Activities
              </h3>

              <p data-aos="fade-up" className="my-4">
                Every student has his/her own true calling. It is up to us to encourage that child to find it by guiding him/her. The child needs the outlet and we try to provide the platform.
              </p>

              <p data-aos="fade-up">
                We encourage all the students to explore their creativity, to present and perform. They are offered opportunities through regular assemblies, inter-house and inter-school competitions and workshops.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoScholasticActivities;