import React, { Component } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import Image2 from "../assets/smart_class.jpg";
import Image3 from "../assets/Achivers6.JPG";
import Image1 from "../assets/world-class-education.JPG";

export class WhatsSetsupart extends Component {
  state = {
    currentIndex: 0,
    items: [
      {
        src: Image1,
        title: "World-Class Education",
        content:
          "We offer a world-class education that empowers students to reach their full potential. Our curriculum is designed to foster critical thinking, creativity, and a love for learning.",
      },
      {
        src: Image2,
        title: "Innovative Learning",
        content:
          "Our innovative learning approaches incorporate the latest technology and teaching methodologies. We strive to create an engaging and interactive learning environment for all students.",
      },
      {
        src: Image3,
        title: "Academic Excellence",
        content:
          "We pride ourselves on our rigorous academic programs that challenge students to think critically and creatively. With a dedicated faculty and a commitment to personalized learning, our students consistently achieve outstanding results.",
      },
    ],
  };

  componentDidMount() {
    Aos.init({ duration: 1000 }); // Initialize AOS with a duration
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  nextImage = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex + 1) % prevState.items.length,
    }));
  };

  prevImage = () => {
    this.setState((prevState) => ({
      currentIndex:
        (prevState.currentIndex - 1 + prevState.items.length) %
        prevState.items.length,
    }));
  };

  render() {
    const { currentIndex, items } = this.state;

    return (
      <div className="flex flex-col lg:flex-row w-full h-auto bg-gray-100">
        {/* Left Side */}
        <div
          className="w-full lg:w-2/5 p-6 flex flex-col justify-center items-center lg:items-start text-center lg:text-left"
          data-aos="fade-right"
        >
          <h2 className="text-2xl lg:text-3xl font-bold" data-aos="fade-up">
            What's Sets Us Apart
          </h2>
          <h3
            className="text-xl lg:text-2xl mt-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {items[currentIndex].title}
          </h3>
          <p
            className="mt-4 text-sm lg:text-base"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {items[currentIndex].content}
          </p>
          <div className="flex justify-center lg:justify-between mt-10 space-x-4">
            <button
              onClick={this.prevImage}
              className="p-3 bg-gray-300 rounded shadow hover:bg-gray-400 transition"
              data-aos="zoom-in"
              data-aos-delay="600"
            >
              <FaArrowLeft />
            </button>
            <button
              onClick={this.nextImage}
              className="p-3 bg-gray-300 rounded shadow hover:bg-gray-400 transition"
              data-aos="zoom-in"
              data-aos-delay="800"
            >
              <FaArrowRight />
            </button>
          </div>
        </div>

        {/* Right Side */}
        <div
          className="w-full lg:w-3/5 p-4 flex flex-col items-center justify-center"
          data-aos="fade-left"
        >
          {/* Carousel Container */}
          <div className="relative w-full flex flex-col lg:flex-row justify-center">
            {/* Mobile View: Single Image Display */}
            <img
              src={items[currentIndex].src}
              alt={`Image ${currentIndex + 1}`}
              className="w-full h-64 object-cover mb-4 lg:hidden rounded-lg shadow-lg transition-transform duration-500 ease-in-out"
              data-aos="zoom-in"
            />

            {/* Desktop View: Original Design */}
            <img
              src={items[currentIndex].src}
              alt={`Image ${currentIndex + 1}`}
              className="hidden lg:block w-4/5 lg:w-3/5 h-auto mb-4 lg:mb-0 lg:z-10 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500 ease-in-out"
              data-aos="zoom-in"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default WhatsSetsupart;