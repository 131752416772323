import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eveentimg from "../assets/text-book.jpg";

const TextBook = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="bg-gray-100 mt-10 py-6" data-aos="fade-up">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl text-center text-[#13366F] transition-colors duration-300 hover:text-blue-600">
            Text Books
          </h2>
        </div>
      </div>
      <div className="bg-white py-10">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center gap-8" style={{ maxWidth: '80%', margin: '0 auto' }}>
          {/* Image Section */}
          <div className="w-full md:w-1/2 flex-shrink-0" style={{ marginRight: '10%' }}>
            <img
              src={eveentimg}
              alt="Text Books"
              className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
            />
          </div>

          {/* Text Section */}
          <div className="w-full md:w-1/2 flex flex-col">
            <h3 
              className="text-lg font-semibold text-[#13366F] transition-colors duration-300 hover:text-blue-600"
              data-aos="fade-up"
            >
              Text Books
            </h3>

            <p data-aos="fade-up" className="my-4 text-gray-700">
              It is rather detrimental to limit curious minds to only text
              book learning. We sincerely believe that the entire world should
              be the text book for children. Hence, the Junior School does not
              have any prescribed text books. Children are taught through
              thoughtfully designed learning units. These are supported by
              material from class libraries, teacher-created resources, and a
              graded reading programme that includes age-appropriate
              literature.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextBook;