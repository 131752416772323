import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import eveentimg from "../assets/curriculum.JPG";

const Curriculum = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="w-full bg-gray-100 mt-10 py-6" data-aos="fade-up">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl text-center text-[#13366F] font-bold transition-colors duration-300 hover:text-blue-600">
            Curriculum
          </h2>
        </div>
      </div>
      <div className="w-full bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center" data-aos="fade-up">
            {/* Image Section */}
            <div className="w-full">
              <img
                src={eveentimg}
                alt="Curriculum"
                className="w-full h-auto transition-transform border rounded-lg duration-300 hover:scale-105"
              />
            </div>

            {/* Text Section */}
            <div className="w-full">
              <h3
                className="transition-colors duration-300 hover:text-blue-600 text-2xl font-semibold mb-4"
                data-aos="fade-up"
              >
                Curriculum
              </h3>

              <p data-aos="fade-up" className="text-gray-600 mb-4">
                The four broad goals of education at St. Joseph’s Mission School
                include the development of the inner and interpersonal self,
                scholarly development, active participation in a wide range of
                opportunities, and creative expressions. To achieve these
                objectives, the curriculum follows the principles of Integrated
                Education. The term curriculum covers everything that happens in
                school and is not limited to the subject areas.
              </p>

              <p data-aos="fade-up" className="font-semibold text-gray-700 mb-4">
                <b>The general aims are common to all areas across the curriculum:</b>
              </p>

              <ul className="list-disc list-inside text-gray-600 space-y-2" data-aos="fade-up">
                <li>To value everyone in the school community.</li>
                <li>To nurture the development of the whole child.</li>
                <li>
                  To provide opportunities to enable children to co-operate and
                  work harmoniously.
                </li>
                <li>
                  To provide a broad and balanced, relevant, and differentiated
                  curriculum.
                </li>
                <li>
                  To provide a successful partnership between home and school.
                </li>
                <li>To use assessment for promoting each child’s progress.</li>
                <li>
                  To cultivate good habits and promote respect for each other.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Curriculum;