import React from "react";
import "./App.css";


import Footer from "./components/Footer";
import { Routes, Route, useLocation, useNavigate  } from "react-router-dom";
import Overview from "./pages/Overview";
import WhyJms from "./pages/WhyJms";
import CheckTransferCertificate from "./pages/CheckTransferCertificate";
import Login from "./pages/Login";
import AdminHome from "./pages/AdminHome";
import Logout from "./pages/Logout";
import ErrorPage from "./pages/ErrorPage";
import ProtectedRoute from "./pages/ProtectedRoute";
import { useState, useEffect } from "react";
import Contact from "./pages/Contact";
import PrincipleDesk from "./pages/PrincipleDesk";
import Facilitator from "./pages/Facilitator";
import SchoolAmenity from "./pages/SchoolAmenity";
import TeacherPupilratio from "./pages/TeacherPupilratio";
import Photos from "./pages/Photos";
import Videos from "./pages/Videos";
import Press from "./pages/Press";
import AcademicOverview from "./pages/AcademicOverview";
import TeachingMethodology from "./pages/TeachingMethodology";
import Curriculum from "./pages/Curriculum";
import TextBook from "./pages/TextBook";
import SmartClasses from "./pages/SmartClasses";
import LearningSpace from "./pages/LearningSpace";
import AdmissionProcess from "./pages/AdmissionProcess";
import RegistrationFromNewSession from "./pages/RegistrationFromNewSession";
import SchoolFees from "./pages/SchoolFees";
import MandatoryPublicDisclosure from "./pages/MandatoryPublicDisclosure";
import BeyondAcadmicOverview from "./pages/BeyondAcadmicOverview";
import Expeditions from "./pages/Expeditions";
import OurPotential from "./pages/OurPotential";
import Sports from "./pages/Sports";
import HostelFacility from "./pages/HostelFacility";
import StudentsClub from "./pages/StudentsClub";
import CoScholasticActivities from "./pages/CoScholasticActivities";
import GeneralInfo from "./pages/GeneralInfo";
import Preloader from "./pages/Preloader";
import AdminNav from "./components/AdminNav";
import AdminAddRegisteration from "./components/AdminAddRegisteration";
import AdminAddPictures from "./components/AdminAddPictures";
import AdminAddMarque from "./components/AdminAddMarque";
import Navbar from "./HomePageComponents/Navbar";
import Home from "./components/Home";
import Contact_details from "./pages/Contact_details";
import ErrorBoundary from "./components/ErrorBoundary";
import AdminAchievers from "./components/AdminAchievers";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminPath = location.pathname.startsWith("/admin");

  const routes = [
    { path: "/", element: <Home />, name: "Home" },
    {
      path: "/school-overview",
      element: <Overview />,
      name: "School Overview",
    },
    {
      path: "/why-st-joseph-mission-school",
      element: <WhyJms />,
      name: "Why SJMS",
    },
    {
      path: "/principalmessage",
      element: <PrincipleDesk />,
      name: "Principal's Message",
    },
    { path: "/facilitator", element: <Facilitator />, name: "Facilitator" },
    {
      path: "/school-amenities",
      element: <SchoolAmenity />,
      name: "School Amenities",
    },
    {
      path: "/teacher-pupil-ratio",
      element: <TeacherPupilratio />,
      name: "Teacher-Pupil Ratio",
    },
    { path: "/photos", element: <Photos />, name: "Photos" },
    { path: "/videos", element: <Videos />, name: "Videos" },
    { path: "/press", element: <Press />, name: "Press" },
    {
      path: "/academic-overview",
      element: <AcademicOverview />,
      name: "Academic Overview",
    },
    {
      path: "/teaching-methodlogy",
      element: <TeachingMethodology />,
      name: "Teaching Methodology",
    },
    {
      path: "/mandatory-public-disclosure",
      element: <MandatoryPublicDisclosure />,
      name: "Mandatory Public Disclosure",
    },
    {
      path: "/general-info",
      element: <GeneralInfo />,
      name: "General Information",
    },
    { path: "/curriculam", element: <Curriculum />, name: "Curriculum" },
    { path: "/text-book", element: <TextBook />, name: "Text Book" },
    {
      path: "/smart-classes",
      element: <SmartClasses />,
      name: "Smart Classes",
    },
    {
      path: "/learning-spaces",
      element: <LearningSpace />,
      name: "Learning Spaces",
    },
    {
      path: "/beyond-academics-overview",
      element: <BeyondAcadmicOverview />,
      name: "Beyond Academics Overview",
    },
    { path: "/expeditions", element: <Expeditions />, name: "Expeditions" },
    {
      path: "/our-potential",
      element: <OurPotential />,
      name: "Our Potential",
    },
    { path: "/sports", element: <Sports />, name: "Sports" },
    { path: "/admin/Contact_details", element: <Contact_details />, name: "Sports" },
    {
      path: "/hostel-facility",
      element: <HostelFacility />,
      name: "Hostel Facility",
    },
    {
      path: "/co-scholastic-activities",
      element: <CoScholasticActivities />,
      name: "Co-Scholastic Activities",
    },
    {
      path: "/students-club",
      element: <StudentsClub />,
      name: "Students Club",
    },
    {
      path: "/admission-process",
      element: <AdmissionProcess />,
      name: "Admission Process",
    },
    {
      path: "/registration-from-new-session",
      element: <RegistrationFromNewSession />,
      name: "Registration from ",
    },
    { path: "/school-fees", element: <SchoolFees />, name: "School Fees" },
    {
      path: "/check-transfer-certificate",
      element: <CheckTransferCertificate />,
      name: "Check Transfer Certificate",
    },
    { path: "/login", element: <Login setIsLoggedIn={setIsLoggedIn} />, name: "Login" },
    {
      path: "/admin",
      element: (
        <ProtectedRoute isLoggedIn={isLoggedIn}>
          <AdminNav isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        </ProtectedRoute>
      ),
      children: [
        { path: "admin-home", element: <AdminHome />, name: "Admin Home" },
        { path: "admin-registration", element: <AdminAddRegisteration />, name: "Admin Registration" },
        { path: "admin-add-images", element: <AdminAddPictures />, name: "Admin Add Images" },
        { path: "admin-add-notice", element: <AdminAddMarque />, name: "Admin Add Notice" },
        { path: "admin-Contact_details", element: <Contact_details/>, name: "Admin Contact_details" },
        { path: "admin-Achievers", element: <AdminAchievers/>, name: "Admin Achivers" },
       
      ],
    },
    { path: "/logout", element: <Logout setIsLoggedIn={setIsLoggedIn} />, name: "Logout" },
    { path: "/contact", element: <Contact />, name: "Contact" },
    { path: "*", element: <ErrorPage />, name: "Error Page" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);
  
    // Simulate loading time (replace with actual logic as needed)
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  
    // Clear credentials if user navigates to `/`
    if (location.pathname === "/") {
      localStorage.removeItem("authToken"); // Clear stored token
      setIsLoggedIn(false); // Reset login state
    }
  }, [location.pathname]); // Trigger when location changes
  
  // // Automatically logout when navigating to '/' while logged in
  // useEffect(() => {
  //   if (isLoggedIn && location.pathname === "/") {
  //     localStorage.removeItem("authToken"); // Clear token
  //     setIsLoggedIn(false); // Update login state
  //     navigate("/login"); // Redirect to login page
  //   }
  // }, [location, isLoggedIn, navigate]);

  if (loading) {
    return <Preloader />; // Show preloader while the app is loading
  }


return (
  <ErrorBoundary>
    <div>
      {!isAdminPath && (
        <Navbar
          routes={routes}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
        />
      )}
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children?.map((child, childIndex) => (
              <Route
                key={childIndex}
                path={child.path}
                element={child.element}
              />
            ))}
          </Route>
        ))}
      </Routes>
      {!isAdminPath && <Footer />}
    </div>
  </ErrorBoundary>
);
}

export default App;
