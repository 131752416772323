import React, { Component } from "react";
import Aos from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import Imagebox1 from "../assets/Background.png";
import Imagebox2 from "../assets/Background1.png";
import Imagebox3 from "../assets/Background2.png";

export class AboutstJosep extends Component {
  componentDidMount() {
    Aos.init({ duration: 1000 }); // Initialize AOS with duration
  }

  handleEnquireNowClick = () => {
    const carouselSection = document.getElementById("carousel-section");
    if (carouselSection) {
      carouselSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <div className="p-6  mt-5 sm:p-8">
      <h2
        className="text-2xl md:text-3xl text-center font-bold mb-8"
        data-aos="fade-up"
      >
        About the St. Joseph's Mission School Curriculum
      </h2>
    
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Box 1 */}
        <div className="bg-[#FCEFE5] flex flex-col p-6 rounded-lg shadow-md">
          <img
            src={Imagebox1}
            alt="About Us"
            className="w-20 h-20 mb-4 object-cover mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
          />
          <h3
            className="font-semibold text-lg text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            PRIMARY YEARS PROGRAMME
          </h3>
          <p
            className="mt-2 text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Nursery - 5
          </p>
          <p
            className="mt-2 text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Our PYP fosters a love for learning and helps young learners develop a
            strong foundation across all subject areas. Through engaging activities
            and hands-on experiences, students develop essential skills and
            attitudes that prepare them for a lifelong journey of learning.
          </p>
          <button
            className="mt-auto bg-[#FBBE94] text-white py-2 px-4 rounded-full w-full transition duration-300 transform hover:scale-105 hover:bg-[#F99A6E]"
            data-aos="fade-up"
            data-aos-delay="500"
            onClick={this.handleEnquireNowClick}
          >
            Enquire Now
          </button>
        </div>
    
        {/* Box 2 */}
        <div className="bg-[#F0F5FD] flex flex-col p-6 rounded-lg shadow-md">
          <img
            src={Imagebox2}
            alt="About Us"
            className="w-20 h-20 mb-4 object-cover mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
          />
          <h3
            className="font-semibold text-lg text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            MIDDLE YEARS PROGRAMME
          </h3>
          <p
            className="mt-2 text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Grades 6 - 10
          </p>
          <p
            className="mt-2 text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            In the MYP, we empower students to become independent thinkers and
            compassionate individuals. With a focus on interdisciplinary learning
            and personal growth, students explore a range of subjects while
            developing critical thinking, communication, and self-management skills.
          </p>
          <button
            className="mt-20 w-38 bg-[#BECBDA] text-white py-2 px-4 rounded-full transition duration-300 transform hover:scale-105 hover:bg-[#A3C2B6]"
            data-aos="fade-up"
            data-aos-delay="500"
            onClick={this.handleEnquireNowClick}
          >
            Enquire Now
          </button>
        </div>
    
        {/* Box 3 */}
        <div className="bg-[#EFF8F2] flex flex-col p-6 rounded-lg shadow-md">
          <img
            src={Imagebox3}
            alt="About Us"
            className="w-20 h-20 mb-4 object-cover mx-auto"
            data-aos="fade-up"
            data-aos-delay="100"
          />
          <h3
            className="font-semibold text-lg text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            DIPLOMA PROGRAMME
          </h3>
          <p
            className="mt-2 text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Grades 11 - 12
          </p>
          <p
            className="mt-2 text-center md:text-left"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            The DP is a transformative two-year
            program that prepares students for
            university and beyond. With a wide range
            of subject choices and an emphasis on
            research and critical analysis, students
            develop a deep understanding of their
            chosen disciplines and become well-
            rounded global citizens.
          </p>
          <button
            className="mt-auto bg-[#9ED2AE] text-white py-2 px-4 rounded-full w-full transition duration-300 transform hover:scale-105 hover:bg-[#8BCDA4]"
            data-aos="fade-up"
            data-aos-delay="500"
            onClick={this.handleEnquireNowClick}
          >
            Enquire Now
          </button>
        </div>
      </div>
    </div>
    
    );
  }
}

export default AboutstJosep;
